import React, { Fragment } from "react";
import { Query } from "@apollo/react-components";

import TemplatePage from "../templates/TemplatePage";
// import TextAndMore from "../ui/atoms/TextAndMore";
// import { homepageText } from "../../utils/content-utils";
import { H4 } from "../ui/particles/Headers";
import Partners from "../ui/molecules/Partners";

import GET_PAGE from "../../graphql/queryPagePartenaires";
import Loading from "../ui/atoms/Loading";
import Error from "../ui/atoms/Error";
import Seo from "../ui/atoms/Seo";

export default props => (
  <Query query={GET_PAGE}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      const {
        page: { heroText, pageTitle }
      } = data;

      return (
        <Fragment>
          <Seo {...data.page} />
          <TemplatePage text={heroText} big={false}>
            <H4 as="h2" center paddingTop={5} paddingBottom={3} colorId="ota">
              {pageTitle}
            </H4>
            <Partners />
          </TemplatePage>
        </Fragment>
      );
    }}
  </Query>
);
