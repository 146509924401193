import styled, { css } from "styled-components/macro";
import { responsiveColumnsTable } from "../../../utils/layout-utils";

export const Div = styled.div`
  ${({ theme }) => theme.noHighlight};
  ${({ theme }) => theme.backgroundProps};
  ${({ theme }) => theme.paddingProps};
  ${({ theme }) => theme.marginProps};
  ${({ theme }) => theme.showProps};
  ${({ theme }) => theme.maxWidthProps};
  ${({ theme }) => theme.ratioProps};
  ${({ theme }) => theme.washProps};
  ${({ theme }) => theme.cursorProps};
  ${({ theme }) => theme.relativeProps};
  ${props => props.theme.centerProps};
`;

export const Span = styled.span`
  ${({ theme }) => theme.noHighlight};
  ${props => props.theme.colorProps};
  ${({ theme }) => theme.backgroundProps};
  ${({ theme }) => theme.paddingProps};
  ${({ theme }) => theme.marginProps};
  ${({ theme }) => theme.showProps};
  ${({ theme }) => theme.maxWidthProps};
  ${({ theme }) => theme.ratioProps};
  ${({ theme }) => theme.washProps};
  ${({ theme }) => theme.cursorProps};
  ${({ theme }) => theme.relativeProps};
`;

export const Hr = styled.hr`
  ${({ theme }) => theme.backgroundProps};
  ${({ theme }) => theme.paddingProps};
  ${({ theme }) => theme.marginProps};
  ${({ theme }) => theme.maxWidthProps};
  ${props => props.width && `width: ${props.width}px`};
`;

const FlexDefault = css`
  ${({ theme }) => theme.noHighlight};
  ${({ theme }) => theme.backgroundProps};
  ${({ theme }) => theme.paddingProps};
  ${({ theme }) => theme.marginProps};
  ${({ theme }) => theme.showProps};
  ${({ theme }) => theme.maxWidthProps};
  ${({ theme }) => theme.ratioProps};
  ${({ theme }) => theme.washProps};
  ${({ theme }) => theme.cursorProps};

  display: flex;
  align-items: ${props => props.alignItems || "stretch"};
  flex-flow: ${props => props.flexFlow || "row wrap"};
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.wrap && `flex-wrap: ${props.wrap}`};
  justify-content: ${props => props.justifyContent || "flex-start"};
  ${props => props.theme.media.md`
    ${props.wrapT && `flex-wrap: ${props.wrapT}`};
    ${props.alignItemsT && `align-items: ${props.alignItemsT}`};
    ${props.justifyContentT && `justify-content: ${props.justifyContentT}`};
  `};
  ${props => props.theme.media.sm`
    ${props.wrapM && `flex-wrap: ${props.wrapM}`};
    ${props.alignItemsM && `align-items: ${props.alignItemsM}`};
    ${props.justifyContentM && `justify-content: ${props.justifyContentM}`};
    /* justify-content: flex-start !important; */
  `};
`;

export const Path = styled.path`
  ${({ theme }) => theme.cursorProps};
  ${({ theme }) => theme.fillColorProps};
  ${({ theme }) => theme.strokeColorProps};
  * {
    ${({ theme }) => theme.cursorProps};
  }
`;
export const G = styled.g`
  ${({ theme }) => theme.cursorProps};
  ${({ theme }) => theme.fillColorProps};
  ${({ theme }) => theme.strokeColorProps};
  * {
    ${({ theme }) => theme.cursorProps};
  }
`;

export const Flex = styled.div`
  ${FlexDefault};
`;

export const Grid = styled.div`
  ${FlexDefault};
  & > * {
    min-width: 0;
    box-sizing: border-box;
  }

  ${props =>
    props.theme.gridColumnSizeProps(
      responsiveColumnsTable(props.columns).desktop
    )};

  ${props => props.theme.media.lg`
    ${() =>
      props.theme.gridColumnSizeProps(
        responsiveColumnsTable(props.columns).laptop
      )}
  `};

  ${props => props.theme.media.md`
    ${() =>
      props.theme.gridColumnSizeProps(
        responsiveColumnsTable(props.columns).tablet
      )}
  `};

  ${props => props.theme.media.sm`
    ${() =>
      props.theme.gridColumnSizeProps(
        responsiveColumnsTable(props.columns).mobile
      )}
  `};
`;
