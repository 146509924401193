import React, { Fragment } from "react";
import { Query } from "@apollo/react-components";

import GET_PAGE from "../../graphql/queryPageSavoirFaire";
import TemplatePage from "../templates/TemplatePage";
import TextAndMore from "../ui/atoms/TextAndMore";
import TextNavigation from "../ui/atoms/TextNavigation";
import { Div } from "../ui/particles/Elements";
import { H4 } from "../ui/particles/Headers";
import Legitimacy from "../ui/atoms/Legitimacy";
import Loading from "../ui/atoms/Loading";
import Error from "../ui/atoms/Error";
import Seo from "../ui/atoms/Seo";

export default props => (
  <Query query={GET_PAGE}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      const {
        page: { textfieldsWithHiddenText, caseStudies, heroText, pageTitle }
      } = data;

      return (
        <Fragment>
          <Seo {...data.page} />
          <TemplatePage text={heroText} big={true}>
            <Div bgColorId="ali">
              <H4 as="h2" center paddingTop={5} paddingBottom={3} colorId="sue">
                {pageTitle}
              </H4>
            </Div>
            <TextNavigation
              links={textfieldsWithHiddenText}
              bgColorId="ali"
              colorId="sue"
            />
            {textfieldsWithHiddenText.map(el => (
              <TextAndMore key={el.id} {...el} />
            ))}
            <Legitimacy items={caseStudies} />
          </TemplatePage>
        </Fragment>
      );
    }}
  </Query>
);
