import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

const client = new ApolloClient({
  uri:
    "https://api-eu-central-1.graphcms.com/v2/ckbt2ztiz03g101yx4ivxgv4f/master"
});

const withApolloProvider = Component => props => {
  return (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  );
};

export default withApolloProvider;

// import React, { useEffect, useState } from "react";
// import { ApolloProvider } from "@apollo/react-hooks";
// import { ApolloClient } from "apollo-client";
// import { InMemoryCache } from "apollo-cache-inmemory";
// import { HttpLink } from "apollo-link-http";
// import { setContext } from "apollo-link-context";
// import { persistCache } from "apollo-cache-persist";
//
// import resolvers from "../graphql/resolvers";
// import typeDefs from "../graphql/local/typeDefs";
//
// const link = new HttpLink({
//   uri: `https://${process.env.REACT_APP_SHOPIFY_STORE}/api/2020-04/graphql.json`
// });
//
// const authLink = setContext((request, { headers }) => ({
//   headers: {
//     ...headers,
//     "X-Shopify-Storefront-Access-Token":
//       process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN
//   }
// }));
//
// // these two links say its possible to persist local cache between refresh
// // https://github.com/apollographql/apollo-cache-persist/issues/262
// // https://spectrum.chat/apollo/apollo-client/need-help-with-local-state-based-on-manage-local-state-in-the-docs~e229b284-d2ac-4271-aee7-9def8cfcb101
// // THIS STRATEGY DID NOT WORK FOR ME: https://stackoverflow.com/questions/59211666/how-to-use-localstorage-with-apollo-client-and-reactjs
//
// const withApolloProvider = Component => props => {
//   const [client, setClient] = useState(undefined);
//   useEffect(() => {
//     const cache = new InMemoryCache();
//
//     const client = new ApolloClient({
//       cache,
//       link: authLink.concat(link),
//       typeDefs,
//       resolvers
//     });
//
//     const initData = {
//       isLoggedIn: false
//     };
//     cache.writeData({ data: initData });
//
//     // See above for additional options, including other storage providers.
//     persistCache({
//       cache,
//       storage: window.localStorage
//     }).then(() => {
//       client.onResetStore(async () => cache.writeData({ data: initData }));
//       setClient(client);
//     });
//     return () => {};
//   }, []);
//   if (client === undefined) return null;
//   return (
//     <ApolloProvider client={client}>
//       <Component {...props} />
//     </ApolloProvider>
//   );
// };
//
// export default withApolloProvider;
