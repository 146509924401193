import React, { useState } from "react";
import styled from "styled-components";

import { H2 } from "../particles/Headers";
import { Flex, Div, Grid } from "../particles/Elements";
import Gutter from "./Gutter";
import { getColor } from "../../../utils/color-utils";
import Button from "./Button";
import { headerBreakpoint } from "../../../constants";

const Span = styled.span`
  ${props => {
    const color = getColor("ota", props.theme);
    return `
      background-image: linear-gradient(to bottom,${color} 0%,${color} 100%);
      background-repeat: no-repeat;
      background-position: 0 95%;
      transition: background-position 0.15s ease-out;
      background-size: 100% 3px;
      text-shadow: none;
      background-repeat: no-repeat;
  `;
  }}
`;
const CircleContainer = styled.div`
  position: relative;
`;

const Circle = styled.div`
  width: 60%;
  padding-bottom: 60%;
  margin-left: 15%;
  border: 35px solid ${props => getColor("ali", props.theme)};
  border-radius: 50%;
  position: relative;
`;

const Image = styled.img`
  object-fit: cover;
  width: calc(60% + 70px);
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 35px;
`;

const TextContainer = styled(Flex)`
  position: relative;
  z-index: 1;
  & > * {
    width: 100%;
  }
`;

const TextNavigationPosition = styled.div`
  position: relative;
  top: -160px;
  visibility: none;
  @media (max-width: ${headerBreakpoint}) {
    top: -21px;
  }
`;

const TextAndMore = props => {
  const [showHiddenText, setShowHiddenText] = useState(false);

  const buttonText = showHiddenText ? "voir -" : "voir +";
  const toggleHiddenText = () => setShowHiddenText(!showHiddenText);

  return (
    <div>
      <TextNavigationPosition className={`text ${props.class}`} />
      <Div marginVertical={10}>
        <Gutter>
          <Grid columns="D2M1">
            <div>
              <CircleContainer>
                {props.image && (
                  <Image src={props.image.url} alt={props.title} />
                )}
                <Circle />
              </CircleContainer>
            </div>
            <TextContainer
              justifyContent="center"
              alignItems="center"
              zIndex={1}
              flexFlow="column wrap"
            >
              <H2 maxWidth={540}>
                <Span>{props.title}</Span>
              </H2>
              <Div
                className="custom-html"
                maxWidth={540}
                marginTop={3}
                marginBottom={3}
                dangerouslySetInnerHTML={{ __html: props.text.html }}
              />
              {showHiddenText && props.hiddenText && (
                <Div
                  className="custom-html"
                  maxWidth={540}
                  marginTop={-3}
                  marginBottom={3}
                  dangerouslySetInnerHTML={{ __html: props.hiddenText.html }}
                />
              )}
              {props.hiddenText && (
                <Button onClick={toggleHiddenText}>{buttonText}</Button>
              )}
            </TextContainer>
          </Grid>
        </Gutter>
      </Div>
    </div>
  );
};

TextAndMore.defautlProps = {
  title: "",
  text: [],
  class: ""
};

export default TextAndMore;
