import React from "react";
import { Grid, Div } from "../particles/Elements";
import Workshop from "../atoms/Workshop";
import Gutter from "../atoms/Gutter";

export default ({ data, className }) => (
  <Gutter className={className}>
    <Div marginHorizontal={-2} paddingBottom={5}>
      <Grid columns="D3M1">
        {data.map(el => (
          <Workshop key={el.title} {...el} />
        ))}
      </Grid>
    </Div>
  </Gutter>
);
