import React from "react";
import PropTypes from "prop-types";
import { Path } from "../particles/Elements";

const SvgMenu = props => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="48px"
    height="48px"
    onClick={props.onClick}
    className={props.className}
    style={props.style}
  >
    {" "}
    <Path
      fillColorId={props.fillColorId}
      d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"
    />
  </svg>
);
SvgMenu.propTypes = {
  fillColorId: PropTypes.string
};
SvgMenu.defaultProps = {
  fillColorId: "ota"
};
export default SvgMenu;
