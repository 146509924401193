// libraries
import { css } from "styled-components/macro";
// utils
import {
  fontSizeTabletAsRatioOfDesktop,
  fontSizeMobileAsRatioOfDesktop,
  fontSizeToRem
} from "./font-utils";
import { getColor, darken } from "./color-utils";
// import { deduplicateArray } from "./array.utils";

// export const fontFaceHelper = theme => {
//   // console.log("fontFaceHelper", theme);
//   const cssToAdd = Object.keys(theme.scaleCategories).map(el => {
//     // console.log("el", el);
//     const typeface = theme.typefaces[theme.scaleCategories[el].typefaceId];
//     // console.log("typeface", typeface);
//     // Wrap css to return in conditional because upon first site creation, there
//     // will be no typefaces.
//     if (typeface) {
//       console.log("typeface.variants", typeface.variants);
//       return typeface.variants
//         .map(ell => {
//           return `
//           @font-face{
//             font-family: "${typeface.fontFamily}";
//             src: local("${
//               typeface.fontFamily
//             }"), url(${require(ell.fontURL)}) format("truetype");
//             font-style: ${ell.fontStyle};
//             font-weight: ${ell.fontWeight};
//           }
//           `;
//         })
//         .join("");
//     } else return el;
//   });
//
//   // console.log("cssToAdd", cssToAdd);
//   const cssToAddFinal = deduplicateArray(cssToAdd).join("");
//   console.log("cssToAddFinal", cssToAddFinal);
//
//   return css`
//     ${cssToAddFinal};
//   `;
// };

export const fontFamilyTypefaceVariationProps = (scaleCategoryId, theme) => {
  const typeface =
    theme.typefaces[theme.scaleCategories[scaleCategoryId].typefaceId];
  // Wrap css to return in conditional because upon first site creation, there
  // will be no typefaces.
  if (typeface) {
    const typefaceVariant = typeface.variants.find(
      el =>
        el.typefaceVariantId ===
        theme.scaleCategories[scaleCategoryId].typefaceVariantId
    );
    return css`
      font-family: ${typeface.fontFamily};
      font-weight: ${typefaceVariant.fontWeight};
      font-style: ${typefaceVariant.fontStyle};
    `;
  }
};

// Iterate through the sizes and create a media template
export const media = theme =>
  Object.keys(theme.breakpoints.styles).reduce((total, currentValue) => {
    total[currentValue] = (...args) => css`
      @media (max-width: ${theme.breakpoints.styles[currentValue] / 16}em) {
        ${css(...args)};
      }
    `;
    return total;
  }, {});

/*============================Grid=============================*/
export const gridColumnSizeProps = columns =>
  columns.reduce(
    (total, el, index) =>
      (total += `
  & > *:nth-child(${columns.length > 1 ? index + 1 : "n"}) {
    flex: 0 0 ${100 / el}%;
  }
`),
    ``
  );

/*============================TEXT=============================*/
// NO HIGHLIGHT ON CLICK
// export const noHighlight = props => css`
//   ${props.noHighlight &&
//     typeof props.noHighlight === "boolean" &&
//     `
//    -webkit-touch-callout: none;
//      -webkit-user-select: none;
//       -khtml-user-select: none;
//         -moz-user-select: none;
//          -ms-user-select: none;
//              user-select: none;
//   `};
// `;

export const ftzProps = (props, ...defaultSize) => {
  if (props.scaleCat) {
    const { scaleCategories, media } = props.theme;

    const fontSize = scaleCategories[props.scaleCat].fontSize || 1;

    // This will be trigger in the following example: <H1 scaleCat="h5">...</H1>
    return css`
      font-size: ${fontSizeToRem(fontSize)} !important;
      ${media.md`font-size: ${fontSizeToRem(
        fontSize * fontSizeTabletAsRatioOfDesktop
      )} !important;`};
      ${media.sm`font-size: ${fontSizeToRem(
        fontSize * fontSizeMobileAsRatioOfDesktop
      )} !important;`};
    `;
  } else {
    // This is when I explicitely set the size, like in H1:    ${props => props.theme.ftzProps(props, props.theme.scaleCategories.h1.fontSize)};

    const defaultSizeItem = defaultSize[0];

    return css`
      ${defaultSizeItem && `font-size: ${fontSizeToRem(defaultSizeItem)}`};
      ${props.theme.media.md`
        ${defaultSizeItem &&
          `font-size: ${fontSizeToRem(
            defaultSizeItem * fontSizeTabletAsRatioOfDesktop
          )}`};
      `};
      ${props.theme.media.sm`
        ${defaultSizeItem &&
          `font-size: ${fontSizeToRem(
            defaultSizeItem * fontSizeMobileAsRatioOfDesktop
          )}`};
      `};
    `;
  }
};

// LETTER SPACING
export const letterSpacingProps = (props, defaultSize = 0) => {
  if (props.scaleCat) {
    return css`
      letter-spacing: ${props.theme.scaleCategories[props.scaleCat]
        .letterSpacing}em;
    `;
  } else {
    return css`
      letter-spacing: ${defaultSize}em;
    `;
  }
};

// MAX WITH
export const maxWidthProps = props => css`
  ${props.maxWidth &&
    typeof props.maxWidth === "number" &&
    `max-width: ${props.maxWidth}px`};
  ${props.maxWidth &&
    typeof props.maxWidth === "boolean" &&
    `max-width: ${props.theme.paragraphMaxWidth}px`};
`;

// COLOR && COLORHOVER
export const colorProps = (props, defaultColor) => css`
  ${(props.colorId || props.color || props.elementColorId || defaultColor) &&
    `color: ${
      props.colorId
        ? getColor(props.colorId, props.theme)
        : props.color || defaultColor
    };`}
`;
export const colorHoverProps = (props, defaultColor) => css`
  ${(props.colorHoverId || props.colorHover || defaultColor) &&
    `
    &:hover {
      color: ${
        props.colorHoverId
          ? getColor(props.colorHoverId, props.theme)
          : props.colorHover || defaultColor
      };
      fill: ${
        props.colorHoverId
          ? getColor(props.colorHoverId, props.theme)
          : props.colorHover || defaultColor
      };
    }
  `};
`;

export const hoverAnimation = props => {
  // Default color on hover is ali darkened
  let color = getColor("ali", props.theme);
  if (props.colorId) {
    color = getColor(props.colorId, props.theme);
  }
  if (props.underlineOnHover)
    return css`
      ${props.scaleCat &&
        `line-height: ${
          props.theme.scaleCategories[props.scaleCat].lineHeight
        }em`};
      display: inline-block;
      transition: background-size ${props.theme.transition.duration.normal}
        ease-out;
      background-image: linear-gradient(to bottom, ${color} 0%, ${color} 100%);
      background-size: 0 2px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 0 90%;

      &:hover {
        transition: background-size ${props.theme.transition.duration.normal}
          ease-out;
        background-size: 100% 2px;
      }
    `;
  if (props.darkenOnHover)
    return props.theme.colorHoverProps(props, darken(color, 20));
};

export const fillColorProps = props => css`
  ${props.fillColorId &&
    `fill: ${
      props.fillColorId === "transparent"
        ? "transparent"
        : getColor(props.fillColorId, props.theme)
    };`};
`;
export const strokeColorProps = props => css`
  ${props.strokeColorId &&
    `stroke: ${
      props.strokeColorId === "transparent"
        ? "transparent"
        : getColor(props.strokeColorId, props.theme)
    };`};
`;

export const underlineProps = props => css`
  ${props.underline && "text-decoration: underline"};
`;

// BACKGROUND COLOR
/**
 * Sets the value of the css property background-color
 * @param  {String} bgColor                   [The css value ]
 * @param  {[type]} bgColorId                 [The color Id value (from our color palette)]
 * @param  {[type]} theme
 * @param  {[type]} bgColorFromElementColorId [The element color Id value]
 */
export const backgroundProps = ({
  bgColor,
  bgColorId,
  theme,
  bgColorFromElementColorId
}) => css`
  ${bgColor && typeof bgColor === "string" && `background-color: ${bgColor}`};
  ${bgColorId && `background-color: ${getColor(bgColorId, theme)}`};
`;

export const bgColorHoverProps = (props, def = "inherit") => css`
  &:hover {
    background-color: ${props.bgColorHover &&
    typeof props.bgColorHover === "string"
      ? props.bgColorHover
      : def};
  }
`;

// MARGIN
export const marginProps = props => {
  const {
    theme: { media, goldenRatio }
  } = props;
  return css`
    ${props.marginBottom &&
      `margin-bottom: ${
        typeof props.marginBottom === "string"
          ? props.marginBottom
          : `${props.marginBottom * goldenRatio}px` || "1em"
      }`};
    ${props.marginTop &&
      `margin-top: ${
        typeof props.marginTop === "string"
          ? props.marginTop
          : `${props.marginTop * goldenRatio}px` || "1em"
      }`};
    ${props.marginLeft &&
      `margin-left: ${
        typeof props.marginLeft === "string"
          ? props.marginLeft
          : `${props.marginLeft * goldenRatio}px` || "1em"
      }`};
    ${props.marginRight &&
      `margin-right: ${
        typeof props.marginRight === "string"
          ? props.marginRight
          : `${props.marginRight * goldenRatio}px` || "1em"
      }`};
    ${props.margin &&
      `margin: ${
        typeof props.margin === "string"
          ? props.margin
          : `${props.margin * goldenRatio}px` || "1em"
      }`};
    ${props.marginVertical &&
      `margin-top: ${
        typeof props.marginVertical === "string"
          ? props.marginVertical
          : `${props.marginVertical * goldenRatio}px` || "1em"
      };
      margin-bottom: ${
        typeof props.marginVertical === "string"
          ? props.marginVertical
          : `${props.marginVertical * goldenRatio}px` || "1em"
      };
    `};
    ${props.marginHorizontal &&
      `margin-left: ${
        typeof props.marginHorizontal === "string"
          ? props.marginHorizontal
          : `${props.marginHorizontal * goldenRatio}px` || "1em"
      };
      margin-right: ${
        typeof props.marginHorizontal === "string"
          ? props.marginHorizontal
          : `${props.marginHorizontal * goldenRatio}px` || "1em"
      };
    `};
    ${media.lg`
      ${props.marginBottomL &&
        `margin-bottom: ${
          typeof props.marginBottomL === "string"
            ? props.marginBottomL
            : `${props.marginBottomL * goldenRatio}px` || "1em"
        }`};
      ${props.marginTopL &&
        `margin-top: ${
          typeof props.marginTopL === "string"
            ? props.marginTopL
            : `${props.marginTopL * goldenRatio}px` || "1em"
        }`};
      ${props.marginLeftL &&
        `margin-left: ${
          typeof props.marginLeftL === "string"
            ? props.marginLeftL
            : `${props.marginLeftL * goldenRatio}px` || "1em"
        }`};
      ${props.marginRightL &&
        `margin-right: ${
          typeof props.marginRightL === "string"
            ? props.marginRightL
            : `${props.marginRightL * goldenRatio}px` || "1em"
        }`};
      ${props.marginL &&
        `margin: ${
          typeof props.marginL === "string"
            ? props.marginL
            : `${props.marginL * goldenRatio}px` || "1em"
        }`};
      ${props.marginVerticalL &&
        `margin-top: ${
          typeof props.marginVerticalL === "string"
            ? props.marginVerticalL
            : `${props.marginVerticalL * goldenRatio}px` || "1em"
        };
        margin-bottom: ${
          typeof props.marginVerticalL === "string"
            ? props.marginVerticalL
            : `${props.marginVerticalL * goldenRatio}px` || "1em"
        };
      `};
      ${props.marginHorizontalL &&
        `margin-left: ${
          typeof props.marginHorizontalL === "string"
            ? props.marginHorizontalL
            : `${props.marginHorizontalL * goldenRatio}px` || "1em"
        };
        margin-right: ${
          typeof props.marginHorizontalL === "string"
            ? props.marginHorizontalL
            : `${props.marginHorizontalL * goldenRatio}px` || "1em"
        };
      `};
    `};
    ${media.md`
      ${props.marginBottomT &&
        `margin-bottom: ${
          typeof props.marginBottomT === "string"
            ? props.marginBottomT
            : `${props.marginBottomT * goldenRatio}px` || "1em"
        }`};
      ${props.marginTopT &&
        `margin-top: ${
          typeof props.marginTopT === "string"
            ? props.marginTopT
            : `${props.marginTopT * goldenRatio}px` || "1em"
        }`};
      ${props.marginLeftT &&
        `margin-left: ${
          typeof props.marginLeftT === "string"
            ? props.marginLeftT
            : `${props.marginLeftT * goldenRatio}px` || "1em"
        }`};
      ${props.marginRightT &&
        `margin-right: ${
          typeof props.marginRightT === "string"
            ? props.marginRightT
            : `${props.marginRightT * goldenRatio}px` || "1em"
        }`};
      ${props.marginT &&
        `margin: ${
          typeof props.marginT === "string"
            ? props.marginT
            : `${props.marginT * goldenRatio}px` || "1em"
        }`};
      ${props.marginVerticalT &&
        `margin-top: ${
          typeof props.marginVerticalT === "string"
            ? props.marginVerticalT
            : `${props.marginVerticalT * goldenRatio}px` || "1em"
        };
        margin-bottom: ${
          typeof props.marginVerticalT === "string"
            ? props.marginVerticalT
            : `${props.marginVerticalT * goldenRatio}px` || "1em"
        };
      `};
      ${props.marginHorizontalT &&
        `margin-left: ${
          typeof props.marginHorizontalT === "string"
            ? props.marginHorizontalT
            : `${props.marginHorizontalT * goldenRatio}px` || "1em"
        };
        margin-right: ${
          typeof props.marginHorizontalT === "string"
            ? props.marginHorizontalT
            : `${props.marginHorizontalT * goldenRatio}px` || "1em"
        };
      `};
    `};
    ${media.sm`
      ${props.marginBottomM &&
        `margin-bottom: ${
          typeof props.marginBottomM === "string"
            ? props.marginBottomM
            : `${props.marginBottomM * goldenRatio}px` || "1em"
        }`};
      ${props.marginTopM &&
        `margin-top: ${
          typeof props.marginTopM === "string"
            ? props.marginTopM
            : `${props.marginTopM * goldenRatio}px` || "1em"
        }`};
      ${props.marginLeftM &&
        `margin-left: ${
          typeof props.marginLeftM === "string"
            ? props.marginLeftM
            : `${props.marginLeftM * goldenRatio}px` || "1em"
        }`};
      ${props.marginRightM &&
        `margin-right: ${
          typeof props.marginRightM === "string"
            ? props.marginRightM
            : `${props.marginRightM * goldenRatio}px` || "1em"
        }`};
      ${props.marginM &&
        `margin: ${
          typeof props.marginM === "string"
            ? props.marginM
            : `${props.marginM * goldenRatio}px` || "1em"
        }`};
      ${props.marginVerticalM &&
        `margin-top: ${
          typeof props.marginVerticalM === "string"
            ? props.marginVerticalM
            : `${props.marginVerticalM * goldenRatio}px` || "1em"
        };
        margin-bottom: ${
          typeof props.marginVerticalM === "string"
            ? props.marginVerticalM
            : `${props.marginVerticalM * goldenRatio}px` || "1em"
        };
      `};
      ${props.marginHorizontalM &&
        `margin-left: ${
          typeof props.marginHorizontalM === "string"
            ? props.marginHorizontalM
            : `${props.marginHorizontalM * goldenRatio}px` || "1em"
        };
        margin-right: ${
          typeof props.marginHorizontalM === "string"
            ? props.marginHorizontalM
            : `${props.marginHorizontalM * goldenRatio}px` || "1em"
        };
      `};
    `};
  `;
};

// PADDING
export const paddingProps = props => {
  const {
    theme: { goldenRatio }
  } = props;

  return css`
    ${props.paddingBottom &&
      `padding-bottom: ${
        typeof props.paddingBottom === "string"
          ? props.paddingBottom
          : `${props.paddingBottom * goldenRatio}px` || "1em"
      }`};
    ${props.paddingTop &&
      `padding-top: ${
        typeof props.paddingTop === "string"
          ? props.paddingTop
          : `${props.paddingTop * goldenRatio}px` || "1em"
      }`};
    ${props.paddingLeft &&
      `padding-left: ${
        typeof props.paddingLeft === "string"
          ? props.paddingLeft
          : `${props.paddingLeft * goldenRatio}px` || "1em"
      }`};
    ${props.paddingRight &&
      `padding-right: ${
        typeof props.paddingRight === "string"
          ? props.paddingRight
          : `${props.paddingRight * goldenRatio}px` || "1em"
      }`};
    ${props.padding &&
      `padding: ${
        typeof props.padding === "string"
          ? props.padding
          : `${props.padding * goldenRatio}px` || "1em"
      }`};
    ${props.paddingVertical &&
      `padding-top: ${
        typeof props.paddingVertical === "string"
          ? props.paddingVertical
          : `${props.paddingVertical * goldenRatio}px` || "1em"
      };
      padding-bottom: ${
        typeof props.paddingVertical === "string"
          ? props.paddingVertical
          : `${props.paddingVertical * goldenRatio}px` || "1em"
      };
    `};
    ${props.paddingHorizontal &&
      `padding-left: ${
        typeof props.paddingHorizontal === "string"
          ? props.paddingHorizontal
          : `${props.paddingHorizontal * goldenRatio}px` || "1em"
      };
      padding-right: ${
        typeof props.paddingHorizontal === "string"
          ? props.paddingHorizontal
          : `${props.paddingHorizontal * goldenRatio}px` || "1em"
      };
    `};
    ${props.theme.media.lg`
      ${props.paddingBottomL &&
        `padding-bottom: ${
          typeof props.paddingBottomL === "string"
            ? props.paddingBottomL
            : `${props.paddingBottomL * goldenRatio}px` || "1em"
        }`};
      ${props.paddingTopL &&
        `padding-top: ${
          typeof props.paddingTopL === "string"
            ? props.paddingTopL
            : `${props.paddingTopL * goldenRatio}px` || "1em"
        }`};
      ${props.paddingLeftL &&
        `padding-left: ${
          typeof props.paddingLeftL === "string"
            ? props.paddingLeftL
            : `${props.paddingLeftL * goldenRatio}px` || "1em"
        }`};
      ${props.paddingRightL &&
        `padding-right: ${
          typeof props.paddingRightL === "string"
            ? props.paddingRightL
            : `${props.paddingRightL * goldenRatio}px` || "1em"
        }`};
      ${props.paddingL &&
        `padding: ${
          typeof props.paddingL === "string"
            ? props.paddingL
            : `${props.paddingL * goldenRatio}px` || "1em"
        }`};
      ${props.paddingVerticalL &&
        `padding-top: ${
          typeof props.paddingVerticalL === "string"
            ? props.paddingVerticalL
            : `${props.paddingVerticalL * goldenRatio}px` || "1em"
        };
        padding-bottom: ${
          typeof props.paddingVerticalL === "string"
            ? props.paddingVerticalL
            : `${props.paddingVerticalL * goldenRatio}px` || "1em"
        };
      `};
      ${props.paddingHorizontalL &&
        `padding-left: ${
          typeof props.paddingHorizontalL === "string"
            ? props.paddingHorizontalL
            : `${props.paddingHorizontalL * goldenRatio}px` || "1em"
        };
        padding-right: ${
          typeof props.paddingHorizontalL === "string"
            ? props.paddingHorizontalL
            : `${props.paddingHorizontalL * goldenRatio}px` || "1em"
        };
      `};
    `};
    ${props.theme.media.md`
      ${props.paddingBottomT &&
        `padding-bottom: ${
          typeof props.paddingBottomT === "string"
            ? props.paddingBottomT
            : `${props.paddingBottomT * goldenRatio}px` || "1em"
        }`};
      ${props.paddingTopT &&
        `padding-top: ${
          typeof props.paddingTopT === "string"
            ? props.paddingTopT
            : `${props.paddingTopT * goldenRatio}px` || "1em"
        }`};
      ${props.paddingLeftT &&
        `padding-left: ${
          typeof props.paddingLeftT === "string"
            ? props.paddingLeftT
            : `${props.paddingLeftT * goldenRatio}px` || "1em"
        }`};
      ${props.paddingRightT &&
        `padding-right: ${
          typeof props.paddingRightT === "string"
            ? props.paddingRightT
            : `${props.paddingRightT * goldenRatio}px` || "1em"
        }`};
      ${props.paddingT &&
        `padding: ${
          typeof props.paddingT === "string"
            ? props.paddingT
            : `${props.paddingT * goldenRatio}px` || "1em"
        }`};
      ${props.paddingVerticalT &&
        `padding-top: ${
          typeof props.paddingVerticalT === "string"
            ? props.paddingVerticalT
            : `${props.paddingVerticalT * goldenRatio}px` || "1em"
        };
        padding-bottom: ${
          typeof props.paddingVerticalT === "string"
            ? props.paddingVerticalT
            : `${props.paddingVerticalT * goldenRatio}px` || "1em"
        };
      `};
      ${props.paddingHorizontalT &&
        `padding-left: ${
          typeof props.paddingHorizontalT === "string"
            ? props.paddingHorizontalT
            : `${props.paddingHorizontalT * goldenRatio}px` || "1em"
        };
        padding-right: ${
          typeof props.paddingHorizontalT === "string"
            ? props.paddingHorizontalT
            : `${props.paddingHorizontalT * goldenRatio}px` || "1em"
        };
      `};
    `};
    ${props.theme.media.sm`
      ${props.paddingBottomM &&
        `padding-bottom: ${
          typeof props.paddingBottomM === "string"
            ? props.paddingBottomM
            : `${props.paddingBottomM * goldenRatio}px` || "1em"
        }`};
      ${props.paddingTopM &&
        `padding-top: ${
          typeof props.paddingTopM === "string"
            ? props.paddingTopM
            : `${props.paddingTopM * goldenRatio}px` || "1em"
        }`};
      ${props.paddingLeftM &&
        `padding-left: ${
          typeof props.paddingLeftM === "string"
            ? props.paddingLeftM
            : `${props.paddingLeftM * goldenRatio}px` || "1em"
        }`};
      ${props.paddingRightM &&
        `padding-right: ${
          typeof props.paddingRightM === "string"
            ? props.paddingRightM
            : `${props.paddingRightM * goldenRatio}px` || "1em"
        }`};
      ${props.paddingM &&
        `padding: ${
          typeof props.paddingM === "string"
            ? props.paddingM
            : `${props.paddingM * goldenRatio}px` || "1em"
        }`};
      ${props.paddingVerticalM &&
        `padding-top: ${
          typeof props.paddingVerticalM === "string"
            ? props.paddingVerticalM
            : `${props.paddingVerticalM * goldenRatio}px` || "1em"
        };
        padding-bottom: ${
          typeof props.paddingVerticalM === "string"
            ? props.paddingVerticalM
            : `${props.paddingVerticalM * goldenRatio}px` || "1em"
        };
      `};
      ${props.paddingHorizontalM &&
        `padding-left: ${
          typeof props.paddingHorizontalM === "string"
            ? props.paddingHorizontalM
            : `${props.paddingHorizontalM * goldenRatio}px` || "1em"
        };
        padding-right: ${
          typeof props.paddingHorizontalM === "string"
            ? props.paddingHorizontalM
            : `${props.paddingHorizontalM * goldenRatio}px` || "1em"
        };
      `};
    `};
  `;
};

// CURSOR
export const cursorProps = ({ pointer }) => css`
  ${pointer && "cursor: pointer"};
`;

// TEXT CENTER
export const centerProps = ({ center }) => css`
  ${center && "text-align: center"};
`;

// RELATIVE PLACEMENT
// export const relativeProps = ({ relative }) => css`
//   ${relative && "position: relative"};
// `;

// CAPITALIZE
export const capitalizeProps = ({ capitalize }) => {
  if (capitalize) {
    return css`
      text-transform: capitalize;
    `;
  }
};

// UPPERCASE
export const uppercaseProps = (props, defaultTransform = "inherit") => css`
  ${!props.uppercaseFalse &&
    `
    text-transform: ${
      props.uppercase && typeof (props.uppercase === "boolean")
        ? props.uppercase
          ? "uppercase; letter-spacing: 1px;"
          : "none;"
        : defaultTransform
    };
  `};
`;
/*=============================================================*/

// ZINDEX
// export const zIndexProps = props => css`
//   ${props.z &&
//     typeof props.z === "number" &&
//     `position: relative; z-index: ${props.z};`};
// `;

// // SHOW / HIDE RESPONSIVE
export const showProps = props => css`
  ${props.showTandM && typeof props.showTandM === "boolean" && "display: none"};
  ${props.showM && typeof props.showM === "boolean" && "display: none"};
  ${props.theme.media.md`
      ${props.showD && typeof props.showD === "boolean" && "display: none"};
      ${props.showTandM &&
        typeof props.showTandM === "boolean" &&
        "display: initial"};
  `};
  ${props.theme.media.sm`
      ${props.showD && typeof props.showD === "boolean" && "display: none"};
      ${props.showDandT &&
        typeof props.showDandT === "boolean" &&
        "display: none"};
      ${props.showM && typeof props.showM === "boolean" && "display: initial"};
  `};
`;

// WASH
// export const washProps = props => css`
//   ${props =>
//     props.withWash &&
//     `
//     opacity: 0.5;
//   `};
// `;

// RATIO
// export const ratioProps = props => css`
//   ${props => props.ratio && `padding-bottom: ${props.ratio}%`};
//   ${props.theme.media.md`
//     ${props => props.ratioT && `padding-bottom: ${props.ratioT}%`};
//     transition: ${props.theme.transition.duration.normal}s;
//   `};
//   ${props.theme.media.sm`
//     ${props => props.ratioM && `padding-bottom: ${props.ratioM}%`};
//     transition: ${props.theme.transition.duration.normal}s;
//   `};
// `;
