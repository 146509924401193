import React from "react";

import ContactPreview from "../atoms/ContactPreview";
import Gutter from "../atoms/Gutter";
import { Flex } from "../particles/Elements";

export default ({ data }) => (
  <Gutter>
    <Flex justifyContent="center">
      {data.map(el => (
        <ContactPreview key={el.id} {...el} />
      ))}
    </Flex>
  </Gutter>
);
