import React from "react";
import styled from "styled-components";

import Paper from "../atoms/Paper";
import { Flex, Div, Hr } from "../particles/Elements";
import { Body1, Caption } from "../particles/Paragraphs";
import { H3, H4 } from "../particles/Headers";
import Superlink from "../atoms/Superlink";
import Button from "../atoms/Button";
import {
  convertDateToDDMMYY,
  getHHMMFromDate
} from "../../../utils/date-utils";

const Container = styled(Div)`
  width: 655px;
  img {
    flex-shrink: 0;
  }
  .partner {
    ${props => props.theme.media.sm`
      flex-flow: row wrap;
      img {
        width: 60%;
        margin-bottom: ${props.theme.goldenRatio * 2}px;
      }
    `};
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 235px;
  height: 235px;
  ${props => props.theme.media.md`
    width: 150px;
    height: 150px;
  `};
`;

const PartnerPreview = ({
  image,
  title,
  topic,
  groups,
  description,
  program,
  conditions,
  cost,
  startDateAndTime,
  endDateAndTime,
  location
}) => {
  const startTime = getHHMMFromDate(startDateAndTime);
  const endTime = getHHMMFromDate(endDateAndTime);
  const date = convertDateToDDMMYY(startDateAndTime);

  const bodyText = `Bonjour, %0D%0A  %0D%0AJe souhaiterais m'inscrire à l'évnènement suivant: %0D%0A %0D%0A nom: "${title}" %0D%0A date: ${date} %0D%0A heure: ${startTime}h-${endTime}h %0D%0A %0D%0A Merci de me recontacter au (INSÉRER VOTRE NUMÉRO). %0D%0A %0D%0A Cordialement, %0D%0A %0D%0A (VOTRE PRÉNOM, NOM ET SOCIÉTÉ)`.replace(
    " ",
    "%20"
  );

  const mailtoLink = `mailto:catherine.pena@happii.fr,joseph.machiah@happii.fr?subject=Inscription&body=${bodyText}`;

  return (
    <Container marginVertical={5}>
      <Paper animateOnHover={false}>
        <H3 marginBottom={5} marginBottomM={3} as="h2">
          {title}
        </H3>
        <Flex flexFlow="row nowrap" className="partner">
          <Image src={image.url} alt={title} />
          <div>
            <Div marginLeft={3} marginLeftM="0" marginTopM={3}>
              <H4 as="h3" marginBottom={1}>
                {topic}
              </H4>
              <Caption>{groups}</Caption>
              <Hr marginLeft="0" marginVertical={1} width="150px" />
              <Body1 marginVertical={1}>{description}</Body1>
              {program && <Body1 marginBottom={1}>{program}</Body1>}
              {conditions && <Body1 marginBottom={1}>{conditions}</Body1>}
              <Hr marginLeft="0" marginVertical={1} width="150px" />
              <Body1 marginBottom={1}>date: {date}</Body1>
              <Body1 marginBottom={1}>
                heure: de {startTime}h à {endTime}h
              </Body1>
              <Body1 marginBottom={1}>coût: {`${cost}€` || "Gratuit"}</Body1>
              {/* <Body1 marginBottom={3}>lieu: {location || "Fortia"}</Body1>*/}
              <Superlink href={mailtoLink}>
                <Button>Inscription</Button>
              </Superlink>
            </Div>
          </div>
        </Flex>
      </Paper>
    </Container>
  );
};

export default PartnerPreview;
