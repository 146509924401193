import gql from "graphql-tag";

export default gql`
  query PartnerOrderedList {
    partnerOrderedList(where: { id: "ckbxg24680mp901053s4ie3z4" }) {
      partners {
        picture {
          url
        }
        description
        email
        firstname
        id
        lastName
        linkedIn
        phone
        position
      }
    }
  }
`;
