import React from "react";
import styled from "styled-components";

import { Caption } from "../particles/Paragraphs";

const CaptionStyled = styled(Caption)`
  display: inline-block;
  line-height: 1;
  padding: 7px;
  margin-right: 7px;
`;

export default ({ categories, tags }) => (
  <div>
    {!!categories.length &&
      categories.map(({ title }) => (
        <CaptionStyled
          key={title}
          marginRight={1}
          colorId="sue"
          bgColorId="ali"
        >
          {title}
        </CaptionStyled>
      ))}
    {!!tags.length &&
      tags.map(({ title }) => (
        <CaptionStyled key={title} marginRight={1}>
          {title}
        </CaptionStyled>
      ))}
  </div>
);
