import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Paper from "../atoms/Paper";
import { Flex, Div, Hr } from "../particles/Elements";
import { Caption } from "../particles/Paragraphs";
import { H4 } from "../particles/Headers";
import SvgArrowDown from "./SvgArrowDown";
import { getColor } from "../../../utils/color-utils";
import { pathnames } from "../../../constants";
import CaptionTagList from "./CaptionTagList";
import { convertDateToDDMMYY } from "../../../utils/date-utils";

const Container = styled(Div)`
  ${props => `
    img {
      flex-shrink: 0;
    }
    cursor: pointer;
    transition: all ${props.theme.transition.duration.normal} ease-out;
    &:hover {
      transition: all ${props.theme.transition.duration.normal} ease-out;
      transform: scale(1.03);
      svg path {
        fill: ${getColor("ali", props.theme)};
      }
    }
  `}
  ${({ theme }) => theme.media.md`
    .custom-page-preview-content-wrapper {
      flex-flow: column;
      align-items: center;
    }
  `};
`;

const Image = styled.img`
  object-fit: contain;
  height: 235px;
  width: 235px;
  ${props => props.theme.media.md`
    width: 100%;
  `};
`;

const Arrow = styled(SvgArrowDown)`
  transform: rotate(-90deg);
`;

const TextContainer = styled(Flex)`
  height: 100%;
`;

const CustomPagePreview = ({
  isCaseStudy,
  image: { url },
  tag,
  categories,
  missionDate,
  title,
  slug
}) => {
  const history = useHistory();

  const onNavigateClick = () =>
    history.push(
      `${isCaseStudy ? pathnames.caseStudies : pathnames.events}/${slug}`
    );

  return (
    <Container marginVertical={5} onClick={onNavigateClick}>
      <Caption>{convertDateToDDMMYY(missionDate)}</Caption>
      <Paper animateOnHover={false}>
        <Flex
          flexFlow="row nowrap"
          className="custom-page-preview-content-wrapper"
        >
          <Image src={url} alt={title} />
          <div>
            <TextContainer
              marginLeft={3}
              flexFlow="column nowrap"
              justifyContent="space-between"
            >
              <div>
                <CaptionTagList categories={categories} tags={tag} />
                {(!!categories.length || !!tag.length) && (
                  <Hr marginHorizontal="0" marginVertical={1} width="100%" />
                )}
                <H4>
                  {title.length > 150 ? `${title.slice(0, 150)}...` : title}
                </H4>
              </div>
              <Div marginLeft={2}>
                <Arrow fillColorId="ota" width="40px" />
              </Div>
            </TextContainer>
          </div>
        </Flex>
      </Paper>
    </Container>
  );
};

export default CustomPagePreview;
