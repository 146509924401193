import gql from "graphql-tag";

export default gql`
  query Page {
    page(where: { pageTitle: "Etudes de Cas" }) {
      seoContent
      heroText
      pageTitle
      pageTabTitle
    }
    caseStudies {
      id
      image {
        url
      }
      tag {
        title
      }
      categories {
        title
      }
      missionDate
      title
      slug
    }
  }
`;
