import React from "react";
import { BrowserRouter } from "react-router-dom";

const withBrowserRouter = Component => props => {
  return (
    <BrowserRouter>
      <Component {...props} />
    </BrowserRouter>
  );
};
export default withBrowserRouter;
