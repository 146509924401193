export const convertDateToDDMMYY = dateIso8601 => {
  const date = new Date(dateIso8601);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getHHMMFromDate = dateIso8601 => {
  const date = new Date(dateIso8601);
  const hours = date.getHours();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  return `${hours}:${minutes}`;
};
