/**
 * Reduces the value of a number by a certain percentage.
 * @param  Number number
 * @param  Number percentage to reduce [ex: 50 -> 50%]
 * @return Number new percentage
 */
export const percentageLess = (number, percentageToReduce) =>
  number - (number * percentageToReduce) / 100;

/**
 * parses a number to a 2 digit number
 * @param  Num number
 * @return Num        number with 2 digit float
 */
export const numberWithTwoDecimals = number =>
  parseFloat(parseFloat(number).toFixed(2));

export const eurosToCents = number => number * 100;
