import gql from "graphql-tag";

export default gql`
  query Page {
    page(where: { pageTitle: "Contact" }) {
      seoContent
      heroText
      pageTitle
      pageTabTitle
    }
    places {
      addressLine1
      addressName
      phone
      phone2
      email
      email2
      svgGraphic {
        url
      }
      zip
      city
    }
  }
`;
