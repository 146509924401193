import gql from "graphql-tag";

export default gql`
  query PageServices {
    page(where: { pageTitle: "Services" }) {
      seoContent
      textfieldsWithHiddenText {
        text {
          html
        }
        hiddenText {
          html
        }
        image {
          url
          fileName
        }
        class
        title
        id
      }
      heroText
      pageTitle
      pageTabTitle
    }
    workshops {
      title
      description
      isVirtual
    }
  }
`;
