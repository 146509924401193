import React from "react";
import { Div } from "../ui/particles/Elements";
import Hero from "../ui/atoms/Hero";

// import useHeightHeader from "../../hooks/useHeightHeader";
const TemplatePage = props => {
  return (
    <Div bgColorId="ben">
      <Hero text={props.text} big={props.big} />
      <div>{props.children}</div>
    </Div>
  );
};

TemplatePage.defaultProps = {
  text: "",
  big: false
};

export default TemplatePage;
