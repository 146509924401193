import React from "react";
import { Path } from "../particles/Elements";

const SvgLogo = props => (
  <svg
    width="431"
    height="140"
    viewBox="0 0 431 140"
    fill="none"
    style={props.style}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      fillColorId={props.fillColorId}
      d="M47.8321 35.1283C56.8152 35.1283 63.5015 37.7824 67.891 43.0906C72.2805 48.2967 74.4752 56.208 74.4752 66.8244V112.148H51.3539V67.8962C51.3539 62.7922 50.3841 59.0662 48.4446 56.7184C46.6072 54.3705 43.6978 53.1966 39.7167 53.1966C34.9189 53.1966 31.0398 54.7278 28.0795 57.7902C25.2212 60.8526 23.7921 64.9359 23.7921 70.0399V112.148H0.670776V4.19777H23.7921V46.9186C26.4462 43.0395 29.8149 40.1302 33.8981 38.1907C38.0834 36.1491 42.7281 35.1283 47.8321 35.1283Z"
    />
    <Path
      fillColorId={props.fillColorId}
      d="M125.117 35.1283C136.345 35.1283 144.614 37.7824 149.922 43.0906C155.332 48.3988 158.038 56.6163 158.038 67.7431V112.148H136.141V100.97C134.61 104.85 132.058 107.912 128.485 110.158C124.912 112.301 120.727 113.373 115.929 113.373C110.825 113.373 106.181 112.352 101.995 110.311C97.9119 108.269 94.6454 105.411 92.1954 101.736C89.8476 98.0611 88.6736 93.9779 88.6736 89.4863C88.6736 83.974 90.0517 79.6355 92.8079 76.471C95.6662 73.3065 100.209 71.0097 106.436 69.5806C112.663 68.1514 121.186 67.4369 132.007 67.4369H135.988V64.6807C135.988 60.1891 135.018 57.0246 133.079 55.1872C131.139 53.3497 127.771 52.431 122.973 52.431C119.298 52.431 115.215 53.0945 110.723 54.4216C106.232 55.7486 101.944 57.5861 97.8609 59.9339L91.7361 44.4687C96.0235 41.8146 101.281 39.6198 107.508 37.8845C113.837 36.047 119.706 35.1283 125.117 35.1283ZM120.982 97.4486C125.474 97.4486 129.098 95.9685 131.854 93.0081C134.61 89.9457 135.988 86.0156 135.988 81.2178V78.6147H133.385C125.117 78.6147 119.298 79.2782 115.929 80.6053C112.663 81.9323 111.029 84.3312 111.029 87.802C111.029 90.5582 111.948 92.855 113.786 94.6925C115.725 96.5299 118.124 97.4486 120.982 97.4486Z"
    />
    <Path
      fillColorId={props.fillColorId}
      d="M221.874 35.1283C228.305 35.1283 234.021 36.8126 239.023 40.1813C244.127 43.4479 248.057 48.0925 250.813 54.1153C253.672 60.036 255.101 66.8754 255.101 74.6336C255.101 82.3917 253.672 89.2311 250.813 95.1518C248.057 100.97 244.178 105.462 239.176 108.626C234.174 111.791 228.407 113.373 221.874 113.373C216.77 113.373 212.125 112.352 207.94 110.311C203.856 108.167 200.692 105.207 198.446 101.43V139.71H175.325V36.9657H198.14V47.5311C200.386 43.652 203.601 40.6406 207.786 38.4969C211.972 36.2512 216.667 35.1283 221.874 35.1283ZM215.136 95.7643C220.547 95.7643 224.732 93.9779 227.692 90.4051C230.653 86.7301 232.133 81.473 232.133 74.6336C232.133 67.6921 230.653 62.3328 227.692 58.5558C224.732 54.6768 220.547 52.7372 215.136 52.7372C209.726 52.7372 205.541 54.6257 202.58 58.4027C199.62 62.0776 198.14 67.3858 198.14 74.3273C198.14 81.1667 199.62 86.4749 202.58 90.2519C205.541 93.9268 209.726 95.7643 215.136 95.7643Z"
    />
    <Path
      fillColorId={props.fillColorId}
      d="M315.78 35.1283C322.211 35.1283 327.928 36.8126 332.93 40.1813C338.034 43.4479 341.964 48.0925 344.72 54.1153C347.578 60.036 349.007 66.8754 349.007 74.6336C349.007 82.3917 347.578 89.2311 344.72 95.1518C341.964 100.97 338.085 105.462 333.083 108.626C328.081 111.791 322.313 113.373 315.78 113.373C310.676 113.373 306.031 112.352 301.846 110.311C297.763 108.167 294.598 105.207 292.352 101.43V139.71H269.231V36.9657H292.046V47.5311C294.292 43.652 297.508 40.6406 301.693 38.4969C305.878 36.2512 310.574 35.1283 315.78 35.1283ZM309.043 95.7643C314.453 95.7643 318.638 93.9779 321.599 90.4051C324.559 86.7301 326.039 81.473 326.039 74.6336C326.039 67.6921 324.559 62.3328 321.599 58.5558C318.638 54.6768 314.453 52.7372 309.043 52.7372C303.632 52.7372 299.447 54.6257 296.487 58.4027C293.526 62.0776 292.046 67.3858 292.046 74.3273C292.046 81.1667 293.526 86.4749 296.487 90.2519C299.447 93.9268 303.632 95.7643 309.043 95.7643Z"
    />
    <Path
      fillColorId={props.fillColorId}
      d="M406.138 36.9657H429.259V112.148H406.138V36.9657ZM430.178 0.829102V22.4192H405.372V0.829102H430.178Z"
    />
    <Path
      fillColorId={props.fillColorId}
      d="M365.96 72.9492H389.081V112.148H365.96V72.9492ZM390 36.8126V58.4027H365.194V36.8126H390Z"
    />
  </svg>
);

SvgLogo.defaultProps = {
  fillColorId: "sue"
};

export default SvgLogo;
