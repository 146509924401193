import React from "react";
import { Div } from "../particles/Elements";
import Gutter from "./Gutter";

const Text = ({ data }) => (
  <Div paddingVertical={10}>
    <Gutter>
      <Div
        className="custom-html"
        maxWidth={540}
        marginTop={3}
        marginBottom={3}
        marginRight="auto"
        marginLeft="auto"
        dangerouslySetInnerHTML={{ __html: data.html }}
      />
    </Gutter>
  </Div>
);

export default Text;
