import React from "react";
import { Path } from "../particles/Elements";

const SvgArrowDown = props => (
  <svg
    className={props.className}
    style={props.style}
    width={props.width}
    onClick={props.onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.162 33.175"
  >
    <Path
      fillColorId={props.fillColorId}
      d="M20.87 23.258l-8.206 8.206a1 1 0 0 1-.2.285l-1.132 1.132a1 1 0 0 1-.75.292 1 1 0 0 1-.75-.292l-1.133-1.133a1 1 0 0 1-.2-.285L.293 23.258a1 1 0 0 1 0-1.415l1.133-1.133a1 1 0 0 1 1.414 0l5.854 5.854V.998a1 1 0 0 1 1-1h1.6a1 1 0 0 1 1 1v25.736l6.025-6.025a1 1 0 0 1 1.415 0l1.133 1.133a1 1 0 0 1 .292.707 1 1 0 0 1-.289.709z"
      data-name="Vereinigungsmenge 8"
    />
  </svg>
);

SvgArrowDown.defaultProps = {
  fillColorId: "sue",
  onClick: () => {},
  style: {},
  width: "20px"
};

export default SvgArrowDown;
