import React from "react";
import { Div } from "../particles/Elements";
import { Subtitle1, Body1 } from "../particles/Paragraphs";

const Error = props => (
  <Div marginVertical={10} marginHorizontal={3}>
    <Subtitle1 center marginTop={3}>
      {console.error(props.err)}
      Un problème technique est survenu.
    </Subtitle1>
    <Body1>{props.text}</Body1>
  </Div>
);

Error.defaultProps = {
  text:
    "Veuillez réessayer plus tard et si le problème persiste, merci de contacter nous contacter - nous remetterons les choses en order sous peu!"
};

export default Error;
