import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Grid, Div } from "../particles/Elements";
import { H4 } from "../particles/Headers";
import Gutter from "./Gutter";
import { pathnames } from "../../../constants";

const Image = styled.img`
  object-fit: contain;
  cursor: pointer;
  ${props => `
    transition: ${props.theme.transition.duration.normal};
    &:hover {
      transform: scale(1.1);
      transition: ${props.theme.transition.duration.normal};
    }
  `}
  // max-width: calc(100% - 14px);
  // padding: 7px;
  max-width: 100%;
`;
const Container = styled.div`
  align-self: center;
  justifycontent: center;
  & > div {
    text-align: center;
  }
`;

export default ({ items }) => {
  const history = useHistory();

  const onLinkClick = slug => () => {
    history.push(`${pathnames.caseStudies}/${slug}`);
  };

  return (
    <Div bgColorId="qui">
      <Gutter>
        <H4 paddingVertical={5} center>
          Etudes de Cas Clients
        </H4>
        <Grid columns="D6T3M2">
          {items.map(el => (
            <Container key={el.title}>
              <div>
                <Image
                  src={el.clientLogo.url}
                  alt={el.title}
                  onClick={onLinkClick(el.slug)}
                />
              </div>
            </Container>
          ))}
        </Grid>
      </Gutter>
    </Div>
  );
};
