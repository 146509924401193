// libraries
import styled from "styled-components/macro";

// TODO: all H1 - H6, and P1-P6 pretty much take in the same functions.
// Build a meta prop function that returns those functions/

export const H1 = styled.h1`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.h1.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.h1.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.h1.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.h1.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.h1.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props => props.theme.fontFamilyTypefaceVariationProps("h1", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
  font-weight: bold;
`;

export const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.h2.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.h2.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.h2.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.h2.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.h2.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props => props.theme.fontFamilyTypefaceVariationProps("h2", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
  font-weight: bold;
`;
export const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.h3.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.h3.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.h3.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.h3.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.h3.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props => props.theme.fontFamilyTypefaceVariationProps("h3", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
  font-weight: bold;
`;
export const H4 = styled.h4`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.h4.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.h4.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.h4.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.h4.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.h4.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props => props.theme.fontFamilyTypefaceVariationProps("h4", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
  font-weight: bold;
`;
export const H5 = styled.h5`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.h5.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.h5.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.h5.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.h5.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.h5.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props => props.theme.fontFamilyTypefaceVariationProps("h5", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
  font-weight: bold;
`;
