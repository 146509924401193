import React from "react";

import TemplatePageCaseStudy from "../templates/TemplatePageCaseStudy";
// import TextAndMore from "../ui/atoms/TextAndMore";
import { H4 } from "../ui/particles/Headers";
import Superlink from "../ui/atoms/Superlink";
import { pathnames } from "../../constants";
import CaseStudy from "../ui/molecules/CaseStudy";

export default props => (
  <TemplatePageCaseStudy>
    <H4 center marginVertical={3}>
      <Superlink href={pathnames.caseStudies}>Back</Superlink>
    </H4>
    <CaseStudy />
  </TemplatePageCaseStudy>
);
