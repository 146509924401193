import gql from "graphql-tag";

export default gql`
  query Page {
    page(where: { pageTitle: "Vos Enjeux" }) {
      seoContent
      textfieldsWithHiddenText {
        text {
          html
        }
        hiddenText {
          html
        }
        image {
          url
          fileName
        }
        class
        title
        id
      }
      heroText
      caseStudies {
        slug
        title
        clientLogo {
          url
        }
      }
      pageTitle
      pageTabTitle
    }
  }
`;

// url(
//   transformation: {
//     image: { resize: { fit: crop, height: 180, width: 300 } }
//   }
// )
