import styled from "styled-components/macro";
import { getColor } from "../../../utils/color-utils";

// box-shadow: 0 0 0 1px ${color1},0 1px 3px 0 ${color2};
const Paper = styled.div`
  ${props => {
    // const color1 = getColor(props.borderColorId, props.theme, 0.05);
    // const color2 = getColor(props.borderColorId, props.theme, 0.15);
    return `
      box-shadow: ${getColor("nan", props.theme, 0.3)} 0 0 20px;
      transition: all ${props.theme.transition.duration.normal} ease-out;
      padding: ${props.theme.goldenRatio * 3}px;
      background-color: ${getColor(
        props.bgColorId,
        props.theme,
        props.bgOpacity
      )};
    `;
  }}
  ${props =>
    props.animateOnHover &&
    `
    &:hover {
      transition: all ${props.theme.transition.duration.normal} ease-out;
      transform: scale(1.03);
    }
    `}
`;

Paper.defaultProps = {
  borderColorId: "ota",
  bgColorId: "sue",
  bgOpacity: "1",
  animateOnHover: false
};

export default Paper;
