import React from "react";
import styled, { css } from "styled-components";

const gutterLeft = css`
  ${({ theme }) => `
    padding-left: ${theme.gutter.lg}px;
    transition: ${theme.transition.duration.normal};
  `};

  ${({ theme }) => theme.media.md`
    padding-left: ${theme.gutter.md}px;
  `};

  ${({ theme }) => theme.media.sm`
    padding-left: ${theme.gutter.sm}px;
  `};
`;

const gutterRight = css`
  ${({ theme }) => `
  padding-right: ${theme.gutter.lg}px;
  transition: ${theme.transition.duration.normal};
`};

  ${({ theme }) => theme.media.md`
  padding-right: ${theme.gutter.md}px;
`};

  ${({ theme }) => theme.media.sm`
  padding-right: ${theme.gutter.sm}px;
`};
`;

const gutterSize = css`
  ${gutterLeft};
  ${gutterRight};
`;

const Gutter = styled.div`
  ${gutterSize};
`;

export const ContentMaxWidth = styled.div`
  max-width: 1210px;
  margin-left: auto;
  margin-right: auto;
`;

export default ({ children, ...rest }) => (
  <ContentMaxWidth {...rest}>
    <Gutter>{children}</Gutter>
  </ContentMaxWidth>
);
