import React from "react";
import { Link } from "react-router-dom";

// ui
import * as A from "./SuperlinkAtoms";

export default props => {
  const {
    children,
    to,
    onMouseOver,
    className,
    style,
    colorId,
    elementColorId,
    ...restProps
  } = props;
  // Returning a wrapper around Link because otherwise, we have to pass both
  // styling props and link props to Link, and Link returns warnings.
  // https://app.asana.com/0/958563628374134/1116204707007132?lg=1554472336739
  return (
    <A.InternalLinkWrapper
      {...restProps}
      className={className}
      style={style}
      colorId={colorId}
      elementColorId={elementColorId}
    >
      <Link to={to} onMouseOver={onMouseOver}>
        {children}
      </Link>
    </A.InternalLinkWrapper>
  );
};
