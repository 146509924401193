export const pathnames = {
  home: "/",
  services: "/services",
  partnaires: "/equipe",
  caseStudies: "/etudes-de-cas",
  events: "/evenements",
  contact: "/contact",
  pacte: "/pacte"
};

export const headerBreakpoint = "1000px";

export const KLAVIYO_METRIC_NAME = "Product Alert Request";
