import React, { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";

import EventPreview from "../molecules/EventPreview";
import Gutter from "../atoms/Gutter";
import { Flex } from "../particles/Elements";
import Loading from "../atoms/Loading";
import Error from "../atoms/Error";
import GET_EVENTS from "../../../graphql/queryEvents";

export default props => {
  const { loading, error, data } = useQuery(GET_EVENTS);

  const futureEventsInChronologicalOrder = useMemo(() => {
    if (data && data.events) {
      const datesFiltered = data.events.filter(el => {
        const today = new Date();
        const yesterday = today.setDate(today.getDate() - 1);
        const eventDateEnd = new Date(el.endDateAndTime);
        return eventDateEnd > yesterday;
      });
      return datesFiltered.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.date) - new Date(b.date);
      });
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Gutter>
      <Flex justifyContent="center">
        {futureEventsInChronologicalOrder.map((el, key) => (
          <EventPreview key={key} {...el} />
        ))}
      </Flex>
    </Gutter>
  );
};
