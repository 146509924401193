import gql from "graphql-tag";

export default gql`
  query Page {
    page(where: { pageTitle: "Pacte" }) {
      seoContent
      textfieldsWithHiddenText {
        text {
          html
        }
        hiddenText {
          html
        }
        image {
          url
          fileName
        }
        class
        title
        id
      }
      heroText
      pageTitle
      pageTabTitle
      pactText {
        html
      }
    }
  }
`;
