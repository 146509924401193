import { createGlobalStyle } from "styled-components/macro";
import { getColor } from "./color-utils";

export const rootFontSize = 0.0625;

// ON-POINT
// 25px, Uppercase - > Header Menu
// 40px (h1) -> Hero TextAndMore
// 20px, (h2) Uppercase -> Page title
// 50px, (h3) -> TextAndMore title
// 27px (h3) -> CustomPagePreview title
// 20px (p) -> TextAndMore text
// 18px (p) -> CustomPagePreview text
// 13px Uppercase -> tag

export const theme = {
  // spacing will be always be a multiple ofthe goldenRatio below
  goldenRatio: 7,

  gutter: {
    lg: 77,
    md: 28,
    sm: 18
  },

  breakpoints: {
    // breakpoints for @media. Only have effect on the css
    styles: {
      lg: 1350, // laptop
      // md: 768, // max tablet
      md: 1200, // max tablet
      sm: 580 // max Mobile
    },
    // Effects javascript properties
    // breakpoints for width 1, 2, 3
    // 3 is above largeWidth
    // 2 is between smallWidth and largeWidth
    // 1 is smaller smallWidth
    content: {
      largeWidth: 2198,
      smallWidth: 767
    }
  },

  transition: {
    // transition times used on css property transition
    duration: {
      slow: "500ms",
      normal: "300ms",
      fast: ""
    }
  },

  // colors, categoriez into primary, secondary, and grayscale
  colors: {
    // Primary
    ali: {
      colorId: "ali",
      name: "ali",
      isDeletable: false,
      palette: "primary",
      value: {
        // rgb(39,79,153)
        // rgb(255, 77, 0)
        r: 255,
        g: 77,
        b: 0,
        a: 1
      },
      // value: {
      //   // rgb(203,44,48)
      //   r: 203,
      //   g: 44,
      //   b: 48,
      //   a: 1
      // },
      metadata: []
    },

    ben: {
      colorId: "ben",
      name: "ben",
      isDeletable: false,
      palette: "primary",
      value: {
        // rgb(235,237,226) // opportunity
        r: 235,
        g: 237,
        b: 226,
        a: 1
      },

      metadata: []
    },

    cal: {
      colorId: "cal", // logo bg color
      name: "cal",
      isDeletable: false,
      palette: "primary",
      value: {
        // rgb(255,230,0)
        r: 255,
        g: 230,
        b: 0,
        a: 1
      },
      metadata: []
    },

    dan: {
      colorId: "dan",
      name: "dan",
      isDeletable: false,
      palette: "primary",
      value: {
        // rgb(43,116,178)
        r: 43,
        g: 116,
        b: 178,
        a: 1
      },
      metadata: []
    },

    // Secondary
    // han: {
    //   colorId: "han",
    //   name: "han",
    //   isDeletable: false,
    //   palette: "secondary",
    //   value: {
    //     // rgb(74, 180, 249)
    //     r: 74,
    //     g: 180,
    //     b: 249,
    //     a: 1
    //   },
    //   metadata: []
    // },
    ivy: {
      colorId: "ivy",
      name: "ivy",
      isDeletable: false,
      palette: "secondary",
      value: {
        // rgb(150, 46, 38)
        r: 150,
        g: 46,
        b: 38,
        a: 1
      },
      metadata: []
    },
    jim: {
      colorId: "jim",
      name: "jim",
      isDeletable: false,
      palette: "secondary",
      value: {
        // rgb(97,206,112)
        r: 97,
        g: 207,
        b: 112,
        a: 1
      },
      metadata: []
    },
    // kay: {
    //   colorId: "kay",
    //   name: "kay",
    //   isDeletable: false,
    //   palette: "secondary",
    //   value: {
    //     // rgb(196, 153, 4)
    //     r: 196,
    //     g: 153,
    //     b: 4,
    //     a: 1
    //   },
    //   metadata: []
    // },
    // leo: {
    //   colorId: "leo",
    //   name: "leo",
    //   isDeletable: false,
    //   palette: "secondary",
    //   value: {
    //     // rgb(214, 120, 9)
    //     r: 214,
    //     g: 120,
    //     b: 9,
    //     a: 1
    //   },
    //   metadata: []
    // },

    // grayscale
    nan: {
      colorId: "nan",
      name: "nan",
      isDeletable: false,
      palette: "grayscale",
      value: {
        // rgb(0, 0, 0)
        r: 0,
        g: 0,
        b: 0,
        a: 1
      },
      metadata: []
    },
    ota: {
      colorId: "ota",
      name: "ota",
      isDeletable: false,
      palette: "grayscale",
      value: {
        // rgb(45, 45, 45)
        r: 45,
        g: 45,
        b: 45,
        a: 1
      },
      metadata: []
    },
    pam: {
      colorId: "pam",
      name: "pam",
      isDeletable: false,
      palette: "grayscale",
      value: {
        // rgb(127, 127, 127)
        r: 127,
        g: 127,
        b: 127,
        a: 1
      },
      metadata: []
    },
    qui: {
      colorId: "qui",
      name: "qui",
      isDeletable: false,
      palette: "grayscale",
      value: {
        // rgb(204, 204, 204)
        r: 204,
        g: 204,
        b: 204,
        a: 1
      },
      metadata: []
    },
    roy: {
      colorId: "roy",
      name: "roy",
      isDeletable: false,
      palette: "grayscale",
      value: {
        // rgb(237, 237, 237)
        r: 237,
        g: 237,
        b: 237,
        a: 1
      },
      metadata: []
    },
    sue: {
      colorId: "sue",
      name: "sue",
      isDeletable: false,
      palette: "grayscale",
      value: {
        // rgb(255, 255, 255)
        r: 255,
        g: 255,
        b: 255,
        a: 1
      },
      metadata: []
    }
  },

  // maximum width of paragraphs
  paragraphMaxWidth: 650,

  typefaces: {},

  scaleCategories: {
    h1: {
      scaleCategoryId: "h1",
      name: "H1",
      typefaceId: null,
      typefaceVariantId: null, // TODO: all the typefacenullt implemented
      case: "none", // ["Capitalize, lowercase, uppercase, none"]
      fontSize: 60 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0.05,
      spaceAfter: 0
    },
    h2: {
      scaleCategoryId: "h2",
      name: "H2",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 48 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    h3: {
      scaleCategoryId: "h3",
      name: "H3",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 48 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    h4: {
      scaleCategoryId: "h4",
      name: "H4",
      typefaceId: null,
      typefaceVariantId: null,
      case: "uppercase",
      fontSize: 21 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0.05,
      spaceAfter: 0
    },
    h5: {
      scaleCategoryId: "h5",
      name: "H5",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 24 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    h6: {
      scaleCategoryId: "h6",
      name: "H6",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 16 * rootFontSize,
      lineHeight: 1.6,
      letterSpacing: 0,
      spaceAfter: 0
    },

    subtitle1: {
      scaleCategoryId: "subtitle1",
      name: "Subtitle 1",
      typefaceId: null,
      typefaceVariantId: null,
      case: "uppercase",
      fontSize: 16 * rootFontSize,
      lineHeight: 1.8,
      letterSpacing: 0.05,
      spaceAfter: 0
    },
    subtitle2: {
      scaleCategoryId: "subtitle2",
      name: "Subtitle 2",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 14 * rootFontSize,
      lineHeight: 1.6,
      letterSpacing: 0.05,
      spaceAfter: 0
    },
    body1: {
      scaleCategoryId: "body1",
      name: "Body 1",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 18 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    body2: {
      scaleCategoryId: "body2",
      name: "Body 2",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 14 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    button: {
      scaleCategoryId: "button",
      name: "Button",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 24 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    caption: {
      scaleCategoryId: "caption",
      name: "Caption",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 14 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    overline: {
      scaleCategoryId: "overline",
      name: "Overline",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 10 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    },
    blockquote: {
      scaleCategoryId: "blockquote",
      name: "Blockquote",
      typefaceId: null,
      typefaceVariantId: null,
      case: "none",
      fontSize: 14 * rootFontSize,
      lineHeight: 1.3,
      letterSpacing: 0,
      spaceAfter: 0
    }
  },

  button: {
    primary: {
      buttonTypeId: "primary",
      // name: 'Primary button',
      borderRadius: 50, // Number
      borderStyle: "unset", // String
      borderWidth: 1, // Number,
      typeColorId: "sue", // String <colorId>,
      borderColorId: "dan", // <colorId>
      backgroundColorId: "dan", // <colorId>
      fadeValueHover: 16,
      fadeValueDisabled: 68,
      marginHorizontal: 4,
      marginVertical: 2
    },
    secondary: {
      buttonTypeId: "secondary",
      // name: 'Secondary button',
      borderRadius: 6, // Number
      borderStyle: "unset", // String
      borderWidth: 0, // Number,
      typeColorId: "nan", // String <colorId>,
      borderColorId: "sue", // <colorId>
      backgroundColorId: "sue", // <colorId>
      fadeValueHover: 16,
      fadeValueDisabled: 68,
      marginHorizontal: 16,
      marginVertical: 4
    },
    tertiary: {
      buttonTypeId: "tertiary",
      // name: 'Tertiary button',
      borderRadius: 6, // Number
      borderStyle: "unset", // String
      borderWidth: 1, // Number,
      typeColorId: "nan", // String <colorId>,
      borderColorId: "sue", // <colorId>
      backgroundColorId: "sue", // <colorId>
      fadeValueHover: 16,
      fadeValueDisabled: 68,
      marginHorizontal: 4,
      marginVertical: 2
    },
    fab: {
      buttonTypeId: "fab",
      borderRadius: 0,
      borderStyle: "unset", // String
      borderWidth: 0, // Number,
      typeColorId: "sue", // String <colorId>,
      borderColorId: "dan", // <colorId>
      backgroundColorId: "dan", // <colorId>
      fadeValueHover: 0,
      fadeValueDisabled: 0,
      marginHorizontal: 4,
      marginVertical: 16
    }
  }
};

// This doesn't work so including font-face manually
// ${props => props.theme.fontFaceHelper(props.theme)};

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body, button, input, textarea, select {
      font-family: 'Nunito Sans', sans-serif;
    }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
  button {
    border: none;
    &:focus {
      outline: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
  }
  .custom-html {
    blockquote {
      margin: 21px 0 21px 26px;
      font-weight: bold;
      font-size: 1.3em;
      ${props => `
        border-left:  7px solid ${getColor("nan", props.theme)};
        padding-left: 20px;
        // color: ${getColor("dan", props.theme)};
      `};
    }
    li {
      list-style-type: initial;
      ${props => `margin-bottom: ${theme.goldenRatio}px`}
    }
    ol {
      li {
        list-style-type: decimal;
      }
    }
    ul {
      margin: 14;
      padding-left: 40px;
    }

    h1{
      margin-bottom: ${props => props.theme.scaleCategories.h1.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.h1.lineHeight}em;
      text-transform: ${props => props.theme.scaleCategories.h1.case || "none"};
      ${props =>
        props.theme.ftzProps(props, props.theme.scaleCategories.h1.fontSize)};
        ${props =>
          props.theme.letterSpacingProps(
            props,
            props.theme.scaleCategories.h1.letterSpacing
          )};
          ${props =>
            props.theme.fontFamilyTypefaceVariationProps("h1", props.theme)};
    }

    h2{
      margin-bottom: ${props => props.theme.scaleCategories.h2.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.h2.lineHeight}em;
      text-transform: ${props => props.theme.scaleCategories.h2.case || "none"};
      ${props =>
        props.theme.ftzProps(props, props.theme.scaleCategories.h2.fontSize)};
        ${props =>
          props.theme.letterSpacingProps(
            props,
            props.theme.scaleCategories.h2.letterSpacing
          )};
          ${props =>
            props.theme.fontFamilyTypefaceVariationProps("h2", props.theme)};
    }

    h3 {
      margin-bottom: ${props => props.theme.scaleCategories.h3.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.h3.lineHeight}em;
      text-transform: ${props => props.theme.scaleCategories.h3.case || "none"};
      ${props =>
        props.theme.ftzProps(props, props.theme.scaleCategories.h3.fontSize)};
        ${props =>
          props.theme.letterSpacingProps(
            props,
            props.theme.scaleCategories.h3.letterSpacing
          )};
          ${props =>
            props.theme.fontFamilyTypefaceVariationProps("h3", props.theme)};
    }

    h4 {
      margin-bottom: ${props => props.theme.scaleCategories.h4.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.h4.lineHeight}em;
      text-transform: ${props => props.theme.scaleCategories.h4.case || "none"};
      ${props =>
        props.theme.ftzProps(props, props.theme.scaleCategories.h4.fontSize)};
        ${props =>
          props.theme.letterSpacingProps(
            props,
            props.theme.scaleCategories.h4.letterSpacing
          )};
          ${props =>
            props.theme.fontFamilyTypefaceVariationProps("h4", props.theme)};
    }

    h5 {
      margin-bottom: ${props => props.theme.scaleCategories.h5.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.h5.lineHeight}em;
      text-transform: ${props => props.theme.scaleCategories.h5.case || "none"};
      ${props =>
        props.theme.ftzProps(props, props.theme.scaleCategories.h5.fontSize)};
        ${props =>
          props.theme.letterSpacingProps(
            props,
            props.theme.scaleCategories.h5.letterSpacing
          )};
          ${props =>
            props.theme.fontFamilyTypefaceVariationProps("h5", props.theme)};
    }

    h6 {
      margin-bottom: ${props => props.theme.scaleCategories.h6.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.h6.lineHeight}em;
      text-transform: ${props => props.theme.scaleCategories.h6.case || "none"};
      ${props =>
        props.theme.ftzProps(props, props.theme.scaleCategories.h6.fontSize)};
        ${props =>
          props.theme.letterSpacingProps(
            props,
            props.theme.scaleCategories.h6.letterSpacing
          )};
          ${props =>
            props.theme.fontFamilyTypefaceVariationProps("h6", props.theme)};
    }

    p, li {
      margin-bottom: ${props => props.theme.scaleCategories.body1.spaceAfter}px;
      line-height: ${props => props.theme.scaleCategories.body1.lineHeight}em;
      text-transform: ${props =>
        props.theme.scaleCategories.body1.case || "none"};
        ${props =>
          props.theme.ftzProps(
            props,
            props.theme.scaleCategories.body1.fontSize
          )};
          ${props =>
            props.theme.letterSpacingProps(
              props,
              props.theme.scaleCategories.body1.letterSpacing
            )};
            ${props =>
              props.theme.fontFamilyTypefaceVariationProps(
                "body1",
                props.theme
              )};
            }
    }
`;
