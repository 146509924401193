import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "../utils/theme-utils";
import * as PR from "../utils/prop-receiver-utils";

const withTheme = Component => props => {
  const themeWithPRAndMedia = {
    // Style theme values.
    ...theme,
    // PropReceivers.
    ...PR,
    media: PR.media(theme)
  };

  return (
    <Fragment>
      <GlobalStyle theme={themeWithPRAndMedia} />
      <ThemeProvider theme={themeWithPRAndMedia}>
        <Component {...props} theme={themeWithPRAndMedia} />
      </ThemeProvider>
    </Fragment>
  );
};
export default withTheme;
