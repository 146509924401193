import React from "react";
import styled from "styled-components";

import { getColor } from "../../../utils/color-utils";
import { Div, Flex, Hr } from "../particles/Elements";
import { Caption, Subtitle1 } from "../particles/Paragraphs";

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  ${props => `
    border: 2px solid ${getColor("qui", props.theme)};
  `};
`;

export default ({ firstname, lastName, picture }) => (
  <Div marginTop={5}>
    <Caption>A post by</Caption>
    <Hr marginHorizontal="0" marginVertical={1} width="100%" />
    <Flex alignItems="center">
      <Image src={picture.url} />
      <Subtitle1 marginLeft={3}>
        {firstname} {lastName}
      </Subtitle1>
    </Flex>
  </Div>
);
