// libraries
import styled from "styled-components/macro";
import { getColor } from "../../../utils/color-utils";

// TODO: refactor prop-receiver functions into one global paragraph-prop-receiver-function

export const Subtitle1 = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.subtitle1.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.subtitle1.lineHeight}em;
  text-transform: ${props =>
    props.theme.scaleCategories.subtitle1.case || "none"};
  ${props =>
    props.theme.ftzProps(
      props,
      props.theme.scaleCategories.subtitle1.fontSize
    )};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.subtitle1.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("subtitle1", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

export const Subtitle2 = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.subtitle2.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.subtitle2.lineHeight}em;
  text-transform: ${props =>
    props.theme.scaleCategories.subtitle2.case || "none"};
  ${props =>
    props.theme.ftzProps(
      props,
      props.theme.scaleCategories.subtitle2.fontSize
    )};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.subtitle2.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("subtitle2", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

export const Body1 = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.body1.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.body1.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.body1.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.body1.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.body1.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("body1", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

// TODO: need to reincorporate font-weight into elements once defaultTheme is completely refactored
// font-weight: ${props => props.theme.scaleCategories.body2.fontWeight};
export const Body2 = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.body2.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.body2.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.body2.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.body2.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.body2.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("body2", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

export const Overline = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.overline.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.overline.lineHeight}em;
  text-transform: ${props =>
    props.theme.scaleCategories.overline.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.overline.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.overline.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("overline", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

export const ButtonBase = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.button.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.button.lineHeight}em;
  text-transform: ${props => props.theme.scaleCategories.button.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.button.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.button.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("button", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

export const Caption = styled.p`
  margin-top: 0;
  margin-bottom: ${props => props.theme.scaleCategories.caption.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.caption.lineHeight}em;
  text-transform: ${props =>
    props.theme.scaleCategories.caption.case || "none"};
  ${props =>
    props.theme.ftzProps(props, props.theme.scaleCategories.caption.fontSize)};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.caption.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};
  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("caption", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
`;

export const Blockquote = styled.p`
  margin-top: 0;
  margin-bottom: ${props =>
    props.theme.scaleCategories.blockquote.spaceAfter}px;
  line-height: ${props => props.theme.scaleCategories.blockquote.lineHeight}em;
  text-transform: ${props =>
    props.theme.scaleCategories.blockquote.case || "none"};
  ${props =>
    props.theme.ftzProps(
      props,
      props.theme.scaleCategories.blockquote.fontSize
    )};
  ${props =>
    props.theme.letterSpacingProps(
      props,
      props.theme.scaleCategories.blockquote.letterSpacing
    )};
  ${props => props.theme.colorProps};
  ${props => props.theme.underlineProps};
  ${props => props.theme.colorHoverProps};
  ${props => props.theme.hoverAnimation};

  ${props => props.theme.backgroundProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.showProps};
  ${props => props.theme.maxWidthProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps("blockquote", props.theme)};
  ${props => props.theme.capitalizeProps};
  ${props => props.theme.cursorProps};
  ${props => props.theme.centerProps};
  ${({ theme }) => `
    padding-left: ${theme.goldenRatio * 3}px;
    border-left: ${theme.goldenRatio}px solid ${getColor("ota", theme)};
  `};
`;
