import React from "react";
import ReactDOM from "react-dom";
import * as compose from "lodash/flowRight";
import HttpsRedirect from "react-https-redirect";

import * as serviceWorker from "./serviceWorker";
import withApolloProvider from "./hoc/withApolloProvider";
import withBrowserRouter from "./hoc/withBrowserRouter";
import withTheme from "./hoc/withTheme";
import RouteController from "./components/RouteController";

const App = props => <RouteController {...props} />;

const EnhancedApp = compose(
  withApolloProvider,
  withBrowserRouter,
  withTheme
)(App);

ReactDOM.render(
  <HttpsRedirect>
    <EnhancedApp />
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
