import React from "react";
import { Query } from "@apollo/react-components";

import GET_PARTNERS from "../../../graphql/queryPartners";
import PartnerPreview from "../atoms/PartnerPreview";
import Gutter from "../atoms/Gutter";
import { Flex } from "../particles/Elements";
import Loading from "../atoms/Loading";
import Error from "../atoms/Error";

export default () => (
  <Query query={GET_PARTNERS}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;
      const {
        partnerOrderedList: { partners }
      } = data;

      return (
        <Gutter>
          <Flex justifyContent="center">
            {partners.map(el => (
              <PartnerPreview key={el.id} {...el} />
            ))}
          </Flex>
        </Gutter>
      );
    }}
  </Query>
);
