import React, { useCallback, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import useEventListener from "../../../hooks/useEventListener";
import { pathnames } from "../../../constants";
import { Flex, Div } from "../particles/Elements";
import Gutter from "../atoms/Gutter";
import Superlink from "../atoms/Superlink";
import { headerContent } from "../../../utils/content-utils";
import SvgMenu from "../atoms/SvgMenu";
import SvgClose from "../atoms/SvgClose";
import SvgLogo from "../atoms/SvgLogo";
import LogoWithMarkup from "../atoms/LogoWithMarkup";
import { getColor } from "../../../utils/color-utils";
import { headerBreakpoint } from "../../../constants";

const SvgLogoStyled = styled(SvgLogo)`
  display: block;
  width: 140px;
  height: auto;
  margin: 0 auto;
`;

const LogoWithMarkupStyled = styled(LogoWithMarkup)`
  display: block;
  width: 200px;
  height: auto;
  top: ${props => `${props.theme.goldenRatio * 2}px`};
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

const HeaderContainerLargeScreen = styled(Flex)`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  .header-large-inner-container {
    ${props => `
      transition: padding ${props.theme.transition.duration.normal} ease-out;
      & > * {
        transition: padding ${props.theme.transition.duration.normal} ease-out;
      }
      `};
  }
  @media (max-width: ${headerBreakpoint}) {
    display: none;
  }
`;

const HeaderContainerSmallScreen = styled(Div)`
  display: none;
  z-index: 5;
  position: fixed;
  ${({ theme }) => `
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  & > ul {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`};
  @media (max-width: ${headerBreakpoint}) {
    display: block;
  }
`;

const SvgStyle = css`
  cursor: pointer;
  display: none;
  z-index: 6;
  position: fixed;
  right: 0;
  top: 0;
  ${props => `
    padding: ${props.theme.goldenRatio * 2}px;
    background-color: ${getColor(props.bgColorId, props.theme)}
`};
  @media (max-width: ${headerBreakpoint}) {
    display: block;
  }
`;
const SvgMenuStyled = styled(SvgMenu)`
  ${SvgStyle};
`;
const SvgCloseStyled = styled(SvgClose)`
  ${SvgStyle};
`;

// TODO: do mobile menu
export default props => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [isMenuBig, setIsMenuBig] = useState(true);
  const history = useHistory();

  const isHome = history.location.pathname === pathnames.home ? true : true;
  // const colorLink = isHome ? "sue" : "ali";
  // const colorBackground = isHome ? "ali" : "sue";
  // const isHome = true;
  const colorLink = "sue";
  const colorBackground = "ali";
  const underlineCurrentPage = pathname =>
    window.location.pathname === pathname;

  const handler = useCallback(
    e => {
      const isWindowScrolled = !!window.scrollY;
      if (isMenuBig && isWindowScrolled) {
        setIsMenuBig(false);
      } else if (!isMenuBig && !isWindowScrolled) {
        setIsMenuBig(true);
      }
    },
    [isMenuBig, setIsMenuBig]
  );

  useEventListener("scroll", handler);

  return (
    <Fragment>
      <HeaderContainerLargeScreen
        id="header"
        as="header"
        isHome={isHome}
        bgColorId={colorBackground}
        justifyContent="center"
        alignItems="center"
        onClick={() => setMobileMenuIsOpen(false)}
      >
        <Gutter>
          <Div
            className="header-large-inner-container"
            paddingHorizontal={3}
            paddingVertical={isMenuBig ? 6 : 3}
          >
            <Superlink href={pathnames.home}>
              <SvgLogoStyled fillColorId={colorLink} />
            </Superlink>
            <Flex
              alignItems="center"
              as="ul"
              justifyContent="center"
              marginTop={3}
            >
              {headerContent.map(el => (
                <li key={el.path}>
                  <Superlink
                    colorId={colorLink}
                    marginHorizontal={3}
                    href={el.path}
                    scaleCat="h6"
                    uppercase
                    underlineAnimation={true}
                    underline={underlineCurrentPage(el.path)}
                  >
                    {el.name}
                  </Superlink>
                </li>
              ))}
            </Flex>
          </Div>
        </Gutter>
      </HeaderContainerLargeScreen>
      {mobileMenuIsOpen ? (
        <SvgCloseStyled
          onClick={() => setMobileMenuIsOpen(false)}
          width="48"
          bgColorId="sue"
          fillColorId="ali"
        />
      ) : (
        <SvgMenuStyled
          onClick={() => setMobileMenuIsOpen(true)}
          fillColorId={colorLink}
          bgColorId={colorBackground}
        />
      )}
      {mobileMenuIsOpen && (
        <HeaderContainerSmallScreen bgColorId="sue">
          <LogoWithMarkupStyled fillColorId="ali" />
          <Flex
            flexFlow="column nowrap"
            alignItems="center"
            as="ul"
            justifyContent="center"
          >
            {headerContent.map(el => (
              <li key={el.path} onClick={() => setMobileMenuIsOpen(false)}>
                <Superlink
                  marginBottom={2}
                  colorId="ali"
                  marginHorizontal={3}
                  href={el.path}
                  scaleCat="h3"
                  uppercase
                  underlineAnimation={true}
                  underline={underlineCurrentPage(el.path)}
                >
                  {el.name}
                </Superlink>
              </li>
            ))}
          </Flex>
        </HeaderContainerSmallScreen>
      )}
    </Fragment>
  );
};
