import gql from "graphql-tag";

export default gql`
  query Events {
    events(last: 50, orderBy: startDateAndTime_ASC) {
      topic
      title
      startDateAndTime
      location {
        latitude
        longitude
      }
      image {
        url
      }
      endDateAndTime
      description
      program
      conditions
      cost
    }
  }
`;
