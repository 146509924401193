import React from "react";
import styled from "styled-components";
import { Div } from "../ui/particles/Elements";
import { headerBreakpoint } from "../../constants";

const Container = styled(Div)`
  padding-top: 175px;
  @media (max-width: ${headerBreakpoint}) {
    padding-top: 76px;
  }
`;

// import useHeightHeader from "../../hooks/useHeightHeader";
const TemplatePage = props => {
  return (
    <Container bgColorId="ben" paddingTop={25}>
      <div>{props.children}</div>
    </Container>
  );
};

TemplatePage.defaultProps = {
  text: "",
  big: false
};

export default TemplatePage;
