import gql from "graphql-tag";

export default gql`
  query CaseStudies {
    caseStudies {
      id
      image {
        url
      }
      tag {
        title
      }
      categories {
        title
      }
      missionDate
      title
      slug
    }
  }
`;
