import gql from "graphql-tag";

export default gql`
  query Page {
    page(where: { pageTitle: "Partenaires" }) {
      seoContent
      heroText
      pageTitle
      pageTabTitle
    }
  }
`;
