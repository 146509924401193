import React from "react";
import styled from "styled-components";
import { Flex, Div } from "../particles/Elements";
import { Body1 } from "../particles/Paragraphs";

// https://codepen.io/martinvd/pen/xbQJom
const Loader = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid black;
  }

  .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid black;
  }

  .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid black;
  }

  @keyframes rotate-one {
    0% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-two {
    0% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-three {
    0% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
  }
`;

const Loading = props => (
  <Div marginVertical={10} marginHorizontal={3}>
    <Flex alignItems="center" justifyContent="center">
      <Loader>
        <div className="inner one" />
        <div className="inner two" />
        <div className="inner three" />
      </Loader>
    </Flex>
    <Body1 center marginTop={3}>
      {props.text}
    </Body1>
  </Div>
);

Loading.defaultProps = {
  text: "Récupération des données en cours"
};

export default Loading;
