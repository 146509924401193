import React from "react";
import styled from "styled-components";

import { Div, Flex } from "../particles/Elements";
import Gutter from "./Gutter";
import Superlink from "./Superlink";
import SvgArrowDown from "./SvgArrowDown";

const Arrow = styled(SvgArrowDown)`
  transform: rotate(-90deg);
`;

const TextNavigation = ({ links, colorId, bgColorId }) => (
  <Div bgColorId={bgColorId} paddingBottom={5}>
    <Gutter>
      {links.map(el => (
        <Flex
          key={el.title}
          alignItems="center"
          colorId={colorId}
          marginBottom={1}
          flexFlow="row nowrap"
        >
          <Arrow fillColorId={colorId} />
          <Superlink
            underlineAnimation={true}
            href={`#${el.class}`}
            colorId={colorId}
            scaleCat="h4"
            marginLeft={3}
          >
            {el.title}
          </Superlink>
        </Flex>
      ))}
    </Gutter>
  </Div>
);

TextNavigation.defaultProps = {
  links: [],
  bgColorId: "ali",
  colorId: "sue"
};

export default TextNavigation;
