import React, { useEffect } from "react";
import styled from "styled-components";

import Gutter from "./Gutter";
import Logomark from "./Logomark";
import { Flex, Div } from "../particles/Elements";
import { H2 } from "../particles/Headers";
import SvgArrowDown from "./SvgArrowDown";
import HeroImagery from "../../../assets/HeroImagery.png";
import HeroImageryTop from "../../../assets/HeroImageryTop.png";
import Button from "./Button";
import Superlink from "./Superlink";
import { pathnames } from "../../../constants";

const Container = styled(Div)`
  ${props => `
    height: ${props.big ? "100vh" : "60vh"};
    position: relative;
    & > *:first-child {
      position: absolute;
      top: calc(50% + 40px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    & > *:last-child {
      position: absolute;
      bottom: 2vh;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      transition: transform ${props.theme.transition.duration.normal} ease-in;
    }
    & > *:last-child:hover {
      transition: transform ${props.theme.transition.duration.normal} ease-in;
      transform: translateX(-50%) scale(1.3);
    }
  `}
  ${props => props.theme.media.md`
    height: ${props.big ? "100vh" : "60vh"};
  `};
  ${props => props.theme.media.sm`
    height: ${props.big ? "100vh" : "80vh"};
  `};
`;

const LogoMarkContainer = styled(Div)`
  ${props => `
    img {
      width: ${props.big ? "255px" : "180px"};
    }
  `}
  ${props => props.theme.media.md`
    img {
      width: ${props.big ? "180px" : "140px"};
    }
  `};
  ${props => props.theme.media.sm`
    img {
      width: ${props.big ? "100px" : "100px"};
    }
  `};
`;
const HeroImageryImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: ${props => (props.big ? 30 : 15)}vw;
`;
const HeroImageryTopImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: ${props => (props.big ? 20 : 15)}vw;
`;

const Hero = props => {
  // const bgColorId = props.big ? "ali" : "sue";
  // const colorId = props.big ? "sue" : "ali";

  const bgColorId = "sue";
  const colorId = "ota";

  const onArrowDownClick = () => {
    const elHeader = document.getElementById("header");
    const headerHeight = elHeader ? elHeader.offsetHeight : 0;
    window.scrollTo(0, window.innerHeight - headerHeight);
  };

  const elLogomark = document.getElementById("logomark-hero");

  useEffect(() => {
    if (elLogomark) {
      window.addEventListener("scroll", () => {
        elLogomark.style.transform = `rotate(${window.pageYOffset / 2}deg)`;
      });
      return window.removeEventListener("scroll", () => {});
    }
  }, [elLogomark]);

  return (
    <Container bgColorId={bgColorId} big={props.big} className="hero">
      <Gutter>
        <Flex flexFlow="row nowrap" alignItems="center">
          <div>
            <LogoMarkContainer big={props.big} colorId={colorId}>
              <Logomark id="logomark-hero" />
            </LogoMarkContainer>
          </div>
          <div>
            <H2
              colorId={colorId}
              as="h1"
              marginLeft={10}
              marginLeftT={6}
              marginLeftM={4}
            >
              {props.text}
            </H2>
            {props.big && (
              <Div
                marginLeft={10}
                marginLeftT={6}
                marginLeftM={4}
                marginTop={5}
              >
                <Superlink href={pathnames.pacte}>
                  <Button>Notre Pacte</Button>
                </Superlink>
              </Div>
            )}
          </div>
        </Flex>
      </Gutter>
      <HeroImageryTopImage
        src={HeroImageryTop}
        alt="hero imagery top"
        big={props.big}
      />
      <HeroImageryImage src={HeroImagery} alt="hero imagery" big={props.big} />
      <SvgArrowDown onClick={onArrowDownClick} fillColorId={colorId} />
    </Container>
  );
};

Hero.defaultProps = {
  big: true,
  text: ""
};

export default Hero;
