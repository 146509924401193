import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Flex, Div, Hr } from "../particles/Elements";
import { H4 } from "../particles/Headers";
import { Body1 } from "../particles/Paragraphs";
import Gutter from "../atoms/Gutter";
import CustomPagePreview from "../atoms/CustomPagePreview";

const Container = styled(Flex)`
  & > *:last-child {
    flex: 0 0 200px;
  }
  ${props => props.theme.media.sm`
    flex-flow: column-reverse nowrap;
  `};
`;

const CustomPageFinder = ({ data, isCaseStudy }) => {
  // Categories
  const [selectedCategory, setSelectedCategory] = useState("");
  const onResetCategoriesClick = () => {
    setSelectedCategory("");
  };
  const onCategoryClick = category => () => {
    setSelectedCategory(selectedCategory === category ? "" : category);
  };
  const isSelectedCategory = category => selectedCategory === category;
  const categories = useMemo(
    () =>
      data.reduce((tot, c) => {
        c.categories.forEach(category => {
          if (!tot.includes(category.title)) tot.push(category.title);
        });
        return tot;
      }, []),
    [data]
  );

  // Tags
  const [selectedTag, setSelectedTag] = useState("");
  const onResetTagsClick = () => {
    setSelectedTag("");
  };
  const onTagClick = tag => () => {
    setSelectedTag(selectedTag === tag ? "" : tag);
  };
  const isSelectedTag = tag => selectedTag === tag;
  const tags = useMemo(
    () =>
      data.reduce((tot, c) => {
        c.tag.forEach(tag => {
          if (!tot.includes(tag.title)) tot.push(tag.title);
        });
        return tot;
      }, []),
    [data]
  );

  const previewsFiltered = useMemo(() => {
    let pagesToReturn = data;
    if (!!selectedCategory) {
      pagesToReturn = pagesToReturn.filter(page =>
        page.categories.find(({ title }) => title === selectedCategory)
      );
    }
    if (!!selectedTag) {
      pagesToReturn = pagesToReturn.filter(page =>
        page.tag.find(({ title }) => selectedTag)
      );
    }
    const pagesToReturnSortedByDate = pagesToReturn.sort(
      (a, b) => new Date(b.missionDate) - new Date(a.missionDate)
    );
    return pagesToReturnSortedByDate;
  }, [data, selectedCategory, selectedTag]);

  return (
    <Gutter>
      <Container justifyContent="space-between" flexFlow="row nowrap">
        <div>
          {previewsFiltered.length ? (
            previewsFiltered.map(el => (
              <CustomPagePreview
                key={el.slug}
                {...el}
                isCaseStudy={isCaseStudy}
              />
            ))
          ) : (
            <Body1>Aucun résultat pour catégorie et tag utilisés</Body1>
          )}
        </div>

        <div>
          <Div marginLeft={6} marginTop={10} marginLeftM="0" marginTopM={3}>
            {!!categories.length && (
              <Div marginBottom={5}>
                <H4 colorId="ali">categories</H4>
                <Hr />
                {/* Add underline effect to things below */}
                <Body1
                  marginBottom={1}
                  darkenOnHover
                  pointer
                  onClick={onResetCategoriesClick}
                >
                  All
                </Body1>
                {categories.map(el => (
                  <Body1
                    marginBottom={1}
                    darkenOnHover
                    pointer
                    key={el}
                    onClick={onCategoryClick(el)}
                    underline={isSelectedCategory(el)}
                  >
                    {el}
                  </Body1>
                ))}
              </Div>
            )}

            {!!tags.length && (
              <Div marginBottom={5}>
                <H4 colorId="ali">tags</H4>
                <Hr />
                {/* Add underline effect to things below */}
                <Body1
                  darkenOnHover
                  marginBottom={1}
                  pointer
                  onClick={onResetTagsClick}
                >
                  All
                </Body1>
                {tags.map(el => (
                  <Body1
                    marginBottom={1}
                    darkenOnHover
                    pointer
                    key={el}
                    onClick={onTagClick(el)}
                    underline={isSelectedTag(el)}
                  >
                    {el}
                  </Body1>
                ))}
              </Div>
            )}
          </Div>
        </div>
      </Container>
    </Gutter>
  );
};

CustomPageFinder.defaultProps = {
  data: []
};

export default CustomPageFinder;
