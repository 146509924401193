import React from "react";
import { ExternalLink } from "./SuperlinkAtoms";

export default props => {
  const onScrollTo = () => {
    const classToScrollTo = props.href.substring(1);
    const elElement = document.getElementsByClassName(classToScrollTo);
    if (elElement && elElement.length) {
      const el = elElement[0];
      const top = el.offsetTop;
      window.scrollTo(0, top);
    }
  };

  return (
    <ExternalLink onClick={onScrollTo} {...props}>
      {props.children}
    </ExternalLink>
  );
};
