import gql from "graphql-tag";

export default gql`
  query Footer {
    footer(where: { id: "ckbte2oyg01j601591hm9g0d9" }) {
      piPartnerLogo {
        url
      }
    }
  }
`;
