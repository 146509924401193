import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Query } from "@apollo/react-components";

import { convertDateToDDMMYY } from "../../../utils/date-utils";
import { extractContent } from "../../../utils/html-utils";

import { Div, Hr } from "../particles/Elements";
import { H3, H4 } from "../particles/Headers";
import { Caption } from "../particles/Paragraphs";
import CaptionTagList from "../atoms/CaptionTagList";
import Gutter from "../atoms/Gutter";
import Paper from "../atoms/Paper";
import CustomPagePreview from "../atoms/CustomPagePreview";
import AuthorDisplay from "../atoms/AuthorDisplay";
import GET_PAGE from "../../../graphql/queryEtudeDeCas";
import GET_CASE_STUDIES from "../../../graphql/queryEtudesDeCas";
import Loading from "../atoms/Loading";
import Error from "../atoms/Error";

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  ${props => `
    margin: ${props.theme.goldenRatio * 6}px 0;
  `};
`;

export default props => {
  const { id } = useParams();

  return (
    <Query query={GET_PAGE} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        const {
          author,
          background,
          categories,
          image,
          opportunity,
          abstract,
          results,
          // slug,
          solution,
          tag,
          title,
          missionDate
        } = data.caseStudy;

        return (
          <Fragment>
            <Helmet>
              <title>Etude de cas - {title}</title>
              <meta
                name="description"
                content={extractContent(background.html)}
              />
            </Helmet>
            <Gutter>
              <Container>
                <Caption>{convertDateToDDMMYY(missionDate)}</Caption>
                <Paper>
                  <CaptionTagList categories={categories} tags={tag} />
                  <Hr marginHorizontal="0" marginVertical={2} width="100%" />
                  <H3>{title}</H3>
                  <Image src={image.url} alt={title} />
                  <H4 paddingTop={3}>Résumé</H4>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{
                      __html: abstract.html
                    }}
                  />
                  <H4 paddingTop={3}>Contexte</H4>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{
                      __html: background.html
                    }}
                  />
                  <H4 paddingTop={3}>Opportunité</H4>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{
                      __html: opportunity.html
                    }}
                  />
                  <H4 paddingTop={3}>Solution</H4>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{ __html: solution.html }}
                  />
                  <H4 paddingTop={3}>Résultat</H4>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{ __html: results.html }}
                  />
                  <AuthorDisplay {...author} />
                </Paper>
              </Container>
              <Div paddingBottom={5} />
              <Query query={GET_CASE_STUDIES}>
                {({ loading, error, data }) => {
                  if (loading || error) return null;
                  const { caseStudies } = data;
                  const caseStudiesToDisplay = caseStudies.reduce(
                    (tot, c) =>
                      c.slug !== id && tot.length < 2 ? [...tot, c] : tot,
                    []
                  );
                  return caseStudiesToDisplay && caseStudiesToDisplay.length ? (
                    <Div paddingBottom={5}>
                      {caseStudiesToDisplay.map(el => (
                        <CustomPagePreview
                          key={el.slug}
                          {...el}
                          isCaseStudy={true}
                        />
                      ))}
                    </Div>
                  ) : null;
                }}
              </Query>
            </Gutter>
          </Fragment>
        );
      }}
    </Query>
  );
};
