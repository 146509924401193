/**
 *
 * Debouce function.
 * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
 *
 * @param  Function the function to delay execution.
 * @param  Number delay: in ms, the delay between calls. Requested calls between the delay will be cancelled.
 */
export const debounce = (func, delay) => {
  let inDebounce;
  inDebounce = undefined;
  return function() {
    let args;
    let context;
    context = this;
    args = arguments;
    clearTimeout(inDebounce);
    return (inDebounce = setTimeout(function() {
      return func.apply(context, args);
    }, delay));
  };
};
