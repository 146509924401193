/**
 * Converts grid column nomenclature to responsive column numbers.
 * @param  String columns
 * @return Object
 */
export const responsiveColumnsTable = columns => {
  // Getting the range to which the columns should span for desktop, tablet, and mobile.
  // For Example, 'D6T8M12' will result to:
  // D = 6
  // T = 8
  // M = 12
  let [dlt, M] = columns.split("M");
  let [dl, T] = dlt.split("T");
  let [d, L] = dl.split("L");
  let D = d.replace("D", "");

  // Fallback for mobile value to tablet or desktop
  // So if we write 'D1', for example, it will result to:
  // desktop = 1
  // table = 1
  // mobile = 1
  let desktop = D || 1;
  let laptop = L || desktop;
  let tablet = T || laptop || desktop;
  let mobile = M || tablet || laptop || desktop;

  // Splitting the values if we have different column sizes for elements marked with "_"
  // For example D2_6_1 would result to:
  // D = [2,6,1]
  // First element would span 2 columns, second 3 columns, and third one column on a 10 column grid
  desktop = desktop.split("_");
  laptop = laptop.split("_");
  tablet = tablet.split("_");
  mobile = mobile.split("_");

  return {
    desktop,
    laptop,
    tablet,
    mobile
  };
};
