import gql from "graphql-tag";

export default gql`
  query CaseStudy($id: String!) {
    caseStudy(where: { slug: $id }) {
      author {
        lastName
        firstname
        picture {
          url
        }
      }
      abstract {
        html
      }
      background {
        html
      }
      categories {
        title
      }
      image {
        url
      }
      opportunity {
        html
      }
      results {
        html
      }
      slug
      solution {
        html
      }
      tag {
        title
      }
      title
      missionDate
    }
  }
`;
