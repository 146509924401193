import React from "react";
import { Path } from "../particles/Elements";

const SvgClose = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={props.width}
    viewBox="0 0 24 24"
    width={props.width}
    onClick={props.onClick}
    className={props.className}
    style={props.style}
  >
    <Path
      fillColorId={props.fillColorId}
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
    />
    <Path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

SvgClose.defaultProps = {
  fillColorId: "ota",
  width: "24"
};

export default SvgClose;
