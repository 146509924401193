import React from "react";

const SvgLinkedIn = props => (
  <svg width={props.width} viewBox="0 0 764.12 194">
    <path d="M0 28.56h30.11V138H85v27.49H0z" data-name="path2 fill" />
    <path
      d="M98.41 72.75h28.5v92.69h-28.5zm14.52-46.34a16.71 16.71 0 1 1-16.67 16.7 16.43 16.43 0 0 1 16.67-16.7z"
      data-name="path3 fill"
    />
    <path
      d="M246.82 28.56h29v81.91l32.26-37.72h35.49L306 115.32l36.57 50.12h-36l-30.65-45.81v45.81h-29z"
      data-name="path4 fill"
    />
    <path
      d="M143 72.75h27.42v12.4h.58c3.76-7.55 13.44-15.09 27.42-15.09 29 0 34.42 19.4 34.42 44.18v50.66h-28.5v-45.27c0-10.77 0-24.79-15.06-24.79s-17.2 11.86-17.2 23.72v45.8h-28.5V72.75z"
      data-name="path5 fill"
    />
    <path
      d="M403.84 108.32a17.59 17.59 0 0 0-17.75-17.79c-12.36 0-19.35 8.63-20.43 17.79zm24.2 41c-9.14 11.86-24.2 18.33-39.26 18.33-28.5 0-51.62-19.41-51.62-49s23.12-49 51.62-49c26.89 0 43.56 19.4 43.56 49v9.16h-66.68c2.15 10.77 10.76 17.78 21.51 17.78 9.14 0 15.59-4.85 20.43-11.32z"
      data-name="path6 fill"
    />
    <path
      d="M492 94.84c-14.52 0-23.12 9.7-23.12 23.71s8.6 23.72 23.12 23.72 23.12-9.7 23.12-23.72-8.57-23.71-23.12-23.71zm49.47 70.6h-26.32V153h-.54c-4.3 6.47-15.59 14.55-28.5 14.55-27.42 0-45.7-19.94-45.7-48 0-25.86 16.13-49.58 42.48-49.58 11.83 0 23.12 3.24 29.57 12.4h.54V28.56h28.5v136.88z"
      data-name="path7 fill"
    />
    <path
      d="M749.6 0H584.52C576.45 0 570 6.47 570 14v166c0 7.54 6.45 14 14.52 14H749.6c8.07 0 14.52-6.47 14.52-14V14c0-7.53-6.45-14-14.52-14z"
      data-name="path8 fill"
    />
    <path
      fill="#EDEBE2"
      d="M599 72.75h28.5v92.69H599V72.75zm14.52-45.81a16.71 16.71 0 1 1-16.67 16.71 16.44 16.44 0 0 1 16.71-16.71z"
      data-name="path9 fill"
    />
    <path
      fill="#EDEBE2"
      d="M645.82 72.75h27.42v12.4h.54c3.77-7.55 13.45-15.09 27.43-15.09 29 0 34.41 19.4 34.41 44.18v50.66h-28.5v-45.27c0-10.77 0-24.79-15-24.79s-17.21 11.86-17.21 23.72v45.8h-28.5V72.75z"
      data-name="path10 fill"
    />
  </svg>
);
SvgLinkedIn.defaultProps = {
  fillColorId: "ota",
  width: "100px"
};
export default SvgLinkedIn;
