import React from "react";
import styled from "styled-components";
import { Query } from "@apollo/react-components";

import GET_FOOTER from "../../../graphql/queryFooter";
import { Flex, Div, Hr } from "../particles/Elements";
import { Subtitle2 } from "../particles/Paragraphs";
import { footerContent } from "../../../utils/content-utils";
import Logomark from "../atoms/Logomark";
import Superlink from "../atoms/Superlink";
import Gutter from "../atoms/Gutter";
import Loading from "../atoms/Loading";
import Error from "../atoms/Error";

const FooterContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.theme.media.md`
    flex-flow: column nowrap;
    align-items: center;
  `};
`;

const LogomarkStyled = styled(Logomark)`
  width: 100px;
  height: auto;
`;

export default () => (
  <Query query={GET_FOOTER}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;
      const {
        footer: { piPartnerLogo }
      } = data;
      return (
        <Div marginBottom={5} marginTop={3}>
          <Gutter>
            <FooterContainerStyled as="ul">
              <li>
                <LogomarkStyled />
              </li>
              <Flex justifyContent="center">
                {footerContent.map(el => (
                  <Div key={el.header} margin={5}>
                    <Subtitle2>{el.header}</Subtitle2>
                    <Hr marginHorizontal="0" marginVertical={2} width="160" />
                    <Flex flexFlow="column nowrap">
                      {el.menuItems.map(ell =>
                        ell.type === "link" ? (
                          <Superlink
                            colorId="ota"
                            scaleCat="subtitle2"
                            underlineAnimation={true}
                            key={ell.text}
                            href={ell.link}
                          >
                            {ell.text}
                          </Superlink>
                        ) : (
                          <Subtitle2 key={ell.text}>{ell.text}</Subtitle2>
                        )
                      )}
                    </Flex>
                  </Div>
                ))}
              </Flex>
              <li>
                <img src={piPartnerLogo.url} alt="P.I. certified partner" />
              </li>
            </FooterContainerStyled>
          </Gutter>
        </Div>
      );
    }}
  </Query>
);
