import React from "react";
import styled from "styled-components";

import Paper from "../atoms/Paper";
import { Flex, Div, Hr } from "../particles/Elements";
import { Body1, Caption } from "../particles/Paragraphs";
import { H3 } from "../particles/Headers";
import Superlink from "../atoms/Superlink";
import SvgLinkedIn from "../atoms/SvgLinkedIn";

const Container = styled(Div)`
  width: 655px;
  img {
    flex-shrink: 0;
  }
  .partner {
    ${props => props.theme.media.sm`
      flex-flow: row wrap;
      img {
        width: 60%;
        margin-bottom: ${props.theme.goldenRatio * 2}px;
      }
    `};
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 235px;
  height: 235px;
  ${props => props.theme.media.md`
    width: 150px;
    height: 150px;
  `};
`;

const PartnerPreview = ({
  picture: { url },
  description,
  email,
  firstname,
  id,
  lastName,
  linkedIn,
  phone,
  position
}) => (
  <Container marginVertical={5}>
    <Paper animateOnHover={false}>
      <Flex flexFlow="row nowrap" className="partner">
        <Image src={url} alt={`${firstname} ${lastName}`} />
        <div>
          <Div marginLeft={3} marginLeftM="0">
            <H3>
              {firstname} {lastName}
            </H3>
            <Hr marginLeft="0" marginVertical={1} width="150px" />
            <Superlink
              scaleCat="body1"
              href={`mailto:${email}`}
              colorId="ota"
              underlineAnimation={false}
            >
              {email}
            </Superlink>
            <Body1 marginVertical={1}>{phone}</Body1>
            {linkedIn && (
              <Superlink href={linkedIn}>
                <SvgLinkedIn width="70px" />
              </Superlink>
            )}
            <Hr marginLeft="0" marginVertical={1} width="150px" />
            <Caption marginBottom={1}>
              {position} - {description}
            </Caption>
          </Div>
        </div>
      </Flex>
    </Paper>
  </Container>
);

export default PartnerPreview;
