// librarie s
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components/macro";

// HOC
//utils
import {
  fade,
  rgbaToCss,
  getColor,
  getButtonBackgroundColor,
  getButtonColor,
  getButtonBorderColor
} from "../../../utils/color-utils";

import { debounce } from "../../../utils/function-utils";
// ui

import { ButtonBase } from "../particles/Paragraphs";

// keyframes returns a unique name based on a hash of the contents of the keyframes
const rippleKeyframe = keyframes`
  to {
    opacity  : 0;
    transform: scale(2);
  }
`;

const ButtonContainer = styled.div`
  ${props => `
    display: inline-block;
    width: ${props.fullWidth ? "100%" : "auto"};
  `};
`;
// documentation on the css
// https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
const ButtonUIStyled = styled(ButtonBase)`
  position: relative;
  overflow: hidden;
  .ripple--container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(0);
  }
  .ripple--container span {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.75;
    background-color: #fff;
    animation: ${rippleKeyframe} 1000ms;
  }

  ${props => `
    display: flex;
    align-items: center;
    justify-content: center;
    line-heigth: 1.5;
    width: ${props.fullWidth ? "100%" : "auto"};
  `};
  ${props => {
    const { theme } = props;
    // Variables used by both disabeld and enabled buttons
    let borderRadius;
    let borderStyle;
    let borderWidth;
    let marginHorizontal;
    let marginVertical;

    if (props.secondary) {
      // SECONDARY
      borderRadius = theme.button.secondary.borderRadius;
      borderStyle = theme.button.secondary.borderStyle;
      borderWidth = theme.button.secondary.borderWidth;
      marginHorizontal = theme.button.secondary.marginHorizontal;
      marginVertical = theme.button.secondary.marginVertical;
    } else if (props.tertiary) {
      // TERTIARY
      borderRadius = theme.button.tertiary.borderRadius;
      borderStyle = theme.button.tertiary.borderStyle;
      borderWidth = theme.button.tertiary.borderWidth;
      marginHorizontal = theme.button.tertiary.marginHorizontal;
      marginVertical = theme.button.tertiary.marginVertical;
    } else {
      // PRIMARY OR DEFAULT
      borderRadius = theme.button.primary.borderRadius;
      borderStyle = theme.button.primary.borderStyle;
      borderWidth = theme.button.primary.borderWidth;
      marginHorizontal = theme.button.primary.marginHorizontal;
      marginVertical = theme.button.primary.marginVertical;
    }

    // DISABLED
    if (props.disabled) {
      let fadeValueDisabled =
        theme.button[
          props.secondary
            ? "secondary"
            : props.tertiary
            ? "tertiary"
            : "primary"
        ].fadeValueDisabled;
      return `
        color: ${getColor("pam", theme)};
        background-color: ${fade(getColor("nan", theme, 1), fadeValueDisabled)};
        border-color: ${fade(getColor("nan", theme, 1), fadeValueDisabled)};
        ${borderRadius !== "" &&
          borderRadius !== null &&
          `border-radius: ${borderRadius}px;`};
        ${borderStyle && `border-style: ${borderStyle};`};
        ${borderWidth !== "" &&
          borderWidth !== null &&
          `border-width: ${borderWidth}px;`};
          padding: ${props.theme.goldenRatio * marginVertical}px ${props.theme
        .goldenRatio * marginHorizontal}px;
      `;
    }

    // NOT DISABLED
    let defaultBoxShadow = `0px 1px ${getColor(
      "nan",
      theme,
      0.2
    )}, 0 2px ${getColor("nan", theme, 0.12)}, 0 0 ${getColor(
      "nan",
      theme,
      0.14
    )}`;
    let bgColor;
    let boxShadow;
    let bgColorHover;
    let color;
    let borderColor;
    let fadeValue;
    // SECONDARY
    if (props.secondary) {
      bgColor = getButtonBackgroundColor({
        buttonType: "secondary",
        defaultColor: rgbaToCss(theme.colors.ben.value),
        theme
      });

      color = getButtonColor({
        buttonType: "secondary",
        defaultColorId: "sue",
        theme
      });
      boxShadow = defaultBoxShadow;
      borderColor = getButtonBorderColor({ buttonType: "secondary", theme });
      fadeValue = theme.button.secondary.fadeValueHover;
    } else if (props.tertiary) {
      // TERTIARY
      bgColor = getButtonBackgroundColor({
        buttonType: "tertiary",
        defaultColor: "transparent",
        theme
      });
      color = getButtonColor({
        buttonType: "tertiary",
        defaultColorId: "dan",
        theme
      });
      boxShadow = "none";
      borderColor = getButtonBorderColor({ buttonType: "tertiary", theme });
      fadeValue = theme.button.tertiary.fadeValueHover;
    } else {
      // PRIMARY OR DEFAULT
      bgColor = getButtonBackgroundColor({
        buttonType: "primary",
        defaultColor: rgbaToCss(theme.colors.nan.value),
        theme
      });
      color = getButtonColor({
        buttonType: "primary",
        defaultColorId: "sue",
        theme
      });
      boxShadow = defaultBoxShadow;
      borderColor = getButtonBorderColor({ buttonType: "primary", theme });
      fadeValue = theme.button.primary.fadeValueHover;
    }

    bgColorHover =
      bgColor === "transparent" ? "transparent" : fade(bgColor, fadeValue);

    return `
      cursor: pointer;
      background-color: ${bgColor};
      color: ${color};
      padding: ${props.theme.goldenRatio * marginVertical}px ${props.theme
      .goldenRatio * marginHorizontal}px;
      box-shadow: ${boxShadow};
      ${borderRadius !== "" &&
        borderRadius !== null &&
        `border-radius: ${borderRadius}px;`};
      ${borderStyle && `border-style: ${borderStyle};`};
      ${borderWidth !== "" &&
        borderWidth !== null &&
        `border-width: ${borderWidth}px;`};
      ${borderColor && `border-color: ${borderColor};`};

      &:hover {
        background-color: ${bgColorHover};
      }
    `;
  }};
`;

class Button extends Component {
  constructor(props) {
    super(props);
    // documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
    // Ref for the button
    this.buttonRef = React.createRef();
    // Ref for the button container (surround div to apppend span tags used for the ripple effect)
    this.rippleContainerRef = React.createRef();
  }

  /**
   * Adds ripple effect.
   * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
   */
  onAddRipple = e => {
    if (this.buttonRef && this.buttonRef.current) {
      // following doc: https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
      const ripple = this.buttonRef.current;

      const rippleContainer = this.rippleContainerRef.current;
      const size = ripple.offsetWidth;
      const pos = ripple.getBoundingClientRect();
      const rippler = document.createElement("span");
      const x = e.pageX - pos.left - size / 2;
      const y = e.pageY - pos.top - size / 2;
      const style = `top: ${y}px; left: ${x}px; height: ${size}px; width: ${size}px;`;
      rippleContainer.appendChild(rippler);
      rippler.setAttribute("style", style);
    }
  };

  /**
   * Callback passed to Button component.
   */
  callback = e => {
    const { disabled, onClick } = this.props;
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
    // Checking button is not disabled, onClick exists and is a function.
    if (!disabled && onClick) {
      onClick();
    }
  };

  /**
   * Removes the span dom element created by function onAddRipple to create the ripple effect
   * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
   * @return {[type]} [description]
   */
  cleanUp = () => {
    let container = this.rippleContainerRef.current;
    if (
      this.rippleContainerRef &&
      this.rippleContainerRef.current &&
      this.rippleContainerRef.current.firstChild
    ) {
      while (this.rippleContainerRef.current.firstChild) {
        container.removeChild(container.firstChild);
      }
    }
  };

  render() {
    const {
      label,
      primary,
      secondary,
      tertiary,
      icon,
      style,
      className,
      children,
      disabled,
      fullWidth,
      theme
    } = this.props;

    const { onClick, ...propsWithoutOnClick } = this.props;

    return (
      <ButtonContainer fullWidth={fullWidth} {...propsWithoutOnClick}>
        <ButtonUIStyled
          {...this.props}
          as="button"
          ref={this.buttonRef}
          onMouseDown={this.onAddRipple}
          onMouseUp={debounce(this.cleanUp, 2000)}
          style={style}
          ripple
          fullWidth={fullWidth}
          disabled={disabled}
          primary={primary}
          secondary={secondary}
          tertiary={tertiary}
          className={className}
          onClick={this.callback}
          theme={theme}
        >
          {icon && React.cloneElement(icon)}
          {label}
          {children}
          <div className="ripple--container" ref={this.rippleContainerRef} />
        </ButtonUIStyled>
      </ButtonContainer>
    );
  }
}

Button.propTypes = {
  label: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  stopPropagation: PropTypes.bool
};

Button.defaultProps = {
  onClick: () => {},
  stopPropagation: true
};

export default Button;
