import React from "react";
import InternalLink from "./InternalLink";
import AnchorLink from "./AnchorLink";
import { ExternalLink } from "./SuperlinkAtoms";

const SuperLink = props => {
  // If link hardcoded
  if (props.href) {
    if (props.href.startsWith("#")) {
      return <AnchorLink {...props} />;
    }
    if (props.href.startsWith("/")) {
      // Removing "target" property from props because when we deconstruct {...props}
      // in <Link> from react-router-dom, it causes the component to malfunction.
      const { target, ...rest } = props;
      return (
        <InternalLink
          {...rest}
          onMouseOver={props.onMouseOver}
          style={props.style}
          className={props.className}
          to={props.href}
          children={props.children}
        />
      );
    } else if (props.href.startsWith("mailto:")) {
      return (
        <ExternalLink
          {...props}
          rel="noopener"
          onMouseOver={props.onMouseOver}
          style={props.style}
          className={props.className}
          alt={props.alt}
          target="_top"
        >
          {props.children}
        </ExternalLink>
      );
    } else {
      return (
        <ExternalLink
          {...props}
          rel="noopener"
          onMouseOver={props.onMouseOver}
          style={props.style}
          className={props.className}
          alt={props.alt}
          target={props.target || "_blank"}
        >
          {props.children}
        </ExternalLink>
      );
    }
  }

  console.error("Link is not valid", props);
  return props.children;
};

export default SuperLink;
