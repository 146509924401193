import styled, { css } from "styled-components/macro";
import { getColor, darken } from "../../../../utils/color-utils";

const Default = css`
  ${props => props.theme.ftzProps};
  ${props => props.theme.letterSpacingProps};
  ${props => props.theme.marginProps};
  ${props => props.theme.paddingProps};
  ${props => props.theme.colorProps(props, getColor("ali", props.theme))};
  ${props => props.theme.backgroundProps};
  ${props => props.theme.bgColorHoverProps};
  ${props => props.theme.uppercaseProps};
  ${props =>
    props.theme.fontFamilyTypefaceVariationProps(
      props.scaleCat || "body1",
      props.theme
    )}
  cursor: pointer;
`;

const DefaultLink = css`
  ${props => {
    // Default color on hover is ali darkened
    let color = getColor("ali", props.theme);
    if (props.colorId) {
      color = getColor(props.colorId, props.theme);
    }
    return props.underlineAnimation
      ? `
      ${props.scaleCat &&
        `line-height: ${
          props.theme.scaleCategories[props.scaleCat].lineHeight
        }em`};
      display: inline-block;
      transition: background-size ${
        props.theme.transition.duration.normal
      } ease-out;
      background-image: linear-gradient(to bottom,${color} 0%,${color} 100%);
      background-size: 0 2px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 0 90%;
      ${props.underline && "background-size: 100% 2px;"};

      &:hover {
        transition: background-size ${
          props.theme.transition.duration.normal
        } ease-out;
        background-size: 100% 2px;
      }
    `
      : props.theme.colorHoverProps(props, darken(color, 20));
  }};
`;

export const InternalLinkWrapper = styled.span`
  a {
    ${Default};
    ${DefaultLink};
  }
`;
export const ExternalLink = styled.a`
  ${Default};
  ${DefaultLink};
`;
