import React, { Fragment } from "react";

import TemplatePage from "../templates/TemplatePage";
import CustomPageFinder from "../ui/molecules/CustomPageFinder";
import { Query } from "@apollo/react-components";

import { H4 } from "../ui/particles/Headers";
import GET_PAGE from "../../graphql/queryPageEtudesDeCas";
import Loading from "../ui/atoms/Loading";
import Error from "../ui/atoms/Error";
import Seo from "../ui/atoms/Seo";

export default props => (
  <Query query={GET_PAGE}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      const {
        page: { heroText, pageTitle },
        caseStudies
      } = data;

      return (
        <Fragment>
          <Seo {...data.page} />
          <TemplatePage text={heroText} big={false}>
            <H4 as="h2" center paddingTop={5} paddingBottom={3} colorId="ota">
              {pageTitle}
            </H4>
          </TemplatePage>
          <CustomPageFinder data={caseStudies} isCaseStudy={true} />
        </Fragment>
      );
    }}
  </Query>
);
