import React, { Fragment } from "react";
import { Query } from "@apollo/react-components";

import TemplatePage from "../templates/TemplatePage";
import { H4 } from "../ui/particles/Headers";
import GET_PAGE from "../../graphql/queryPagePacte";
import Loading from "../ui/atoms/Loading";
import Error from "../ui/atoms/Error";
import Seo from "../ui/atoms/Seo";
import Text from "../ui/atoms/Text";
import TextAndMore from "../ui/atoms/TextAndMore";

export default props => (
  <Query query={GET_PAGE}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      const {
        page: { heroText, pageTitle, pactText, textfieldsWithHiddenText }
      } = data;

      console.log("pactText", pactText);

      return (
        <Fragment>
          <Seo {...data.page} />
          <TemplatePage text={heroText} big={false}>
            <H4 as="h2" center paddingTop={5} paddingBottom={3} colorId="ota">
              {pageTitle}
            </H4>
            {pactText && pactText.html && <Text data={pactText} />}
            {!!(textfieldsWithHiddenText && textfieldsWithHiddenText.length) &&
              textfieldsWithHiddenText.map(el => (
                <TextAndMore key={el.id} {...el} />
              ))}
            }
          </TemplatePage>
        </Fragment>
      );
    }}
  </Query>
);
