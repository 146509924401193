/**
 * Converts fontSize number to Rem
 * @param  Number fontSize
 * @return String Fontsize with rem units
 */
export const fontSizeToRem = fontSize => `${fontSize}rem`;

// The size of fonts for responsive screen sizes.
export const fontSizeTabletAsRatioOfDesktop = 0.8;
export const fontSizeMobileAsRatioOfDesktop = 0.6;
