import { pathnames } from "../constants";

// NOT USED
// export const articlesMockContent = [
//   {
//     picture:
//       "https://drive.google.com/uc?export=view&id=10pE-WwvIt9tm-KYrpiifeIdjGI_2QRzb",
//     slug: "article-1",
//     title:
//       "How well is your project doing? – Check it out with ON-POINT’s project assessment framework.",
//     date: "21.4.2020",
//     categories: ["Company news", "Job Openings"],
//     tags: ["Partners", "Zurich", "Cut-Over"],
//     html: `<p>...</p>`,
//     authorId: "josephMachiah"
//   }
// ];

export const headerContent = [
  {
    name: "Vos Enjeux",
    path: pathnames.home
  },
  {
    name: "Services",
    path: pathnames.services
  },
  {
    name: "Pacte",
    path: pathnames.pacte
  },
  {
    name: "Etudes de Cas",
    path: pathnames.caseStudies
  },
  {
    name: "Evenements",
    path: pathnames.events
  },
  {
    name: "Equipe",
    path: pathnames.partnaires
  },
  {
    name: "Contact",
    path: pathnames.contact
  }
];

export const footerContent = [
  {
    header: "Pages du site",
    menuItems: [
      {
        text: "Vos Enjeux",
        type: "link",
        link: pathnames.home
      },
      {
        text: "Services",
        type: "link",
        link: pathnames.services
      },
      {
        text: "Pacte",
        type: "link",
        link: pathnames.pacte
      },
      {
        text: "Etudes de cas",
        type: "link",
        link: pathnames.caseStudies
      },
      {
        text: "Evénements",
        type: "link",
        link: pathnames.events
      },
      {
        text: "Equipe",
        type: "link",
        link: pathnames.partnaires
      },

      {
        text: "Contact",
        type: "link",
        link: pathnames.contact
      }
    ]
  },
  {
    header: "Contacts",
    menuItems: [
      {
        text: "Catherine Pena : +337 86 48 34 33",
        type: "text"
      },
      {
        text: "catherine.pena@happii.fr",
        type: "link",
        link: "mailto:catherine.pena@happii.fr"
      },
      {
        text: "Joseph Machiah : +336 09 86 32 46",
        type: "text"
      },
      {
        text: "joseph.machiah@happii.fr",
        type: "link",
        link: "mailto:joseph.machiah@happii.fr"
      }
    ]
  }
];
