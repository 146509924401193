import React from "react";
import styled from "styled-components";
import Paper from "./Paper";
import { Body1, Caption } from "../particles/Paragraphs";
import { H4 } from "../particles/Headers";
import { Div } from "../particles/Elements";
import Superlink from "./Superlink";

const Ribbon = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: auto;
  right: 0;
  transform: rotate(90deg);
  width: 150px;
  overflow: hidden;
  height: 150px;
  & > * {
    text-align: center;
    left: 0;
    width: 200%;
    transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    margin-top: 35px;
    font-size: 13px;
    line-height: 2;
    font-weight: 800;
    text-transform: uppercase;
  }
`;

export default ({ title, description, isVirtual }) => {
  const bodyText = `Bonjour, %0D%0A  %0D%0AJe souhaiterais m'inscrire à la formation suivante: %0D%0A %0D%0A nom: "${title}" %0D%0A %0D%0A Merci de me recontacter au (INSÉRER VOTRE NUMÉRO). %0D%0A %0D%0A Cordialement, %0D%0A %0D%0A (VOTRE PRÉNOM, NOM ET SOCIÉTÉ)`.replace(
    " ",
    "%20"
  );

  const mailtoLink = `mailto:catherine.pena@happii.fr,joseph.machiah@happii.fr?subject=Inscription%20Formation&body=${bodyText}`;
  return (
    <div style={{ display: "flex" }}>
      <Div margin={2} style={{ display: "flex" }}>
        <Superlink href={mailtoLink} style={{ display: "flex" }}>
          <Paper animateOnHover={true} style={{ position: "relative" }}>
            <Div padding={3}>
              {isVirtual && (
                <Ribbon>
                  <Caption bgColorId="jim" colorId="ota">
                    virtual
                  </Caption>
                </Ribbon>
              )}
              <H4 marginBottom={1} colorId="ota" center={true}>
                {title}
              </H4>
              <Body1 colorId="ota" center={true}>
                {description}
              </Body1>
            </Div>
          </Paper>
        </Superlink>
      </Div>
    </div>
  );
};
