import React from "react";
import styled from "styled-components";
import { H3 } from "../particles/Headers";
import { Body1 } from "../particles/Paragraphs";
import { Hr, Div, Flex } from "../particles/Elements";
import Superlink from "./Superlink";

const Image = styled.img`
  width: 540px;
  max-width: 100%;
`;

const ContactPreview = ({
  addressLine1,
  addressName,
  phone,
  phone2,
  email,
  email2,
  svgGraphic: { url },
  zip,
  city
}) => (
  <Div marginVertical={5}>
    <Image src={url} alt={city} />
    <Flex marginTop={3} justifyContent="center">
      <div>
        <H3>{city}</H3>
        <Hr width="150px" marginLeft="0" marginVertical={2} />
        <Body1 marginBottom={1}>{addressName}</Body1>
        <Body1 marginBottom={1}>{addressLine1}</Body1>
        <Body1 marginBottom={3}>
          {city} {zip}
        </Body1>
        <Body1 marginBottom={1}>{phone}</Body1>
        <Body1>
          <Superlink href={`mailto:${email}`}>{email}</Superlink>
        </Body1>
        {!!(phone2 || email2) && <Div marginTop={3} />}
        {phone2 && <Body1 marginBottom={1}>{phone2}</Body1>}
        {email2 && (
          <Body1>
            <Superlink href={`mailto:${email2}`}>{email2}</Superlink>
          </Body1>
        )}
      </div>
    </Flex>
  </Div>
);

export default ContactPreview;
