import { percentageLess } from "./number-utils";
import convert from "color-convert";

/**
 * Converts rgba values stored as object to a string that is formated for css.
 * The r,g, and b values are rounded.
 *
 * @param {object} rgba { r, g, b, a } an RGBA Object
 */
export const rgbaToCss = ({ r, g, b, a }) =>
  `rgba(${Math.round(r)},${Math.round(g)},${Math.round(b)},${a})`;

/**
 * Gets the css color value from a colorId.
 * @param  String colorId     the id of the color
 * @param  Object theme       the theme
 * @param  Number [opacity=1] opacity (from 0 - 1)
 * @return String             returns rgba value for css
 */
export const getColor = (colorId, theme, opacity = 1) => {
  const rgba = { ...theme.colors[colorId].value, a: opacity };
  return rgbaToCss(rgba);
};

/**
 * Decreases the opacity of an rgba color property.
 *
 * @param  String rgba           The rgba value.
 * @param  Number fadePercentage The percentage to which the alpha just deccrease. Ex: 30 -> 30%
 * @return string                The new rgba value with faded alpha.
 */
export const fade = (rgba, fadePercentage) => {
  if (!rgba) {
    console.error(
      "rgba in fade function should not be empty.",
      rgba,
      fadePercentage
    );
    return rgba;
  }

  return rgba.replace(/\d(?=\D*$)/g, alpha =>
    percentageLess(alpha, fadePercentage)
  );
};

/**
 * Returns the background color for a Button component.
 *
 * @param {*} {
 *   buttonType,
 *   defaultColor,
 *   theme,
 *   isOnHover = false,
 * }
 * @returns String
 */
export const getButtonBackgroundColor = ({
  buttonType,
  defaultColor,
  theme,
  isOnHover = false
}) => {
  switch (theme.button[buttonType].backgroundColorId) {
    case "transparent":
      return "transparent";
    case null:
    case "":
    case "default":
      return defaultColor;
    default:
      const backgroundColor = rgbaToCss(
        theme.colors[theme.button[buttonType].backgroundColorId].value
      );
      return isOnHover
        ? fade(backgroundColor, theme.button[buttonType].fadeValueHover)
        : backgroundColor;
  }
};

/**
 * Returns the color for a Button component.
 *
 * @param  Object buttonProps { buttonType, defaultColorId, theme }
 * @return String The CSS prop value.
 */
export const getButtonColor = ({ buttonType, defaultColorId, theme }) =>
  rgbaToCss(
    theme.button[buttonType].typeColorId
      ? theme.colors[theme.button[buttonType].typeColorId].value
      : theme.colors[defaultColorId].value
  );

/**
 * Returns the border color for a Button component.
 *
 * @param  object buttonProps { buttonType, theme }
 * @return string The CSS prop value.
 */
export const getButtonBorderColor = ({ buttonType, theme }) =>
  theme.button[buttonType].borderColorId
    ? rgbaToCss(theme.colors[theme.button[buttonType].borderColorId].value)
    : null;

/**
 * Increases the darkness of an rgba color property.
 *
 * @param  {string} rgba           [description]
 * @param  {number} fadePercentage [ex: 50 (50%)]
 * @return {string}                [rgba darkened]
 */
export const darken = (rgba, fadePercentage) => {
  if (!rgba) {
    console.error(
      "rgba in fade function should not be empty.",
      rgba,
      fadePercentage
    );
    return rgba;
  }
  const [r, g, b, a] = rgba.match(/(\d+\.\d+|\d+)/g);
  if (r === undefined || g === undefined || b === undefined) {
    console.error(
      "r, g, b values cannot be undefined",
      rgba,
      fadePercentage,
      r,
      g,
      b
    );
    return rgba;
  }
  const [h, s, l] = convert.rgb.hsl.raw([Number(r), Number(g), Number(b)]);
  const newL = percentageLess(l, fadePercentage);
  const [newR, newG, newB] = convert.hsl.rgb.raw([h, s, newL]);
  const newRgba = { r: newR, g: newG, b: newB, a };

  return rgbaToCss(newRgba);
};
